import React, { useState, useEffect, useMemo } from "react";
import { getGroupData } from "components/GlobeComponents/api";
import { isEqual } from 'lodash';
import BuildingIndex from "../BuildIndex";

const NavGroupContext = React.createContext(null);
const NavGroupConsumer = NavGroupContext.Consumer;

function NavGroupProvider({ children }){
const [spiritual, setSpiritual] = useState({ id: 2, selected: true, data: undefined, code: ["ADHNC", "GSPAC"], filterOpen: false, trigger: false, altered: false });
const [natural, setNatural] = useState({ id: 3, selected: true, data: undefined, code: ["GLBZT", "INFST", "PBSVC", "TCHGY", "CTCHG", "CTRSK", "ENVHT", "RSCSC", "AGCLT", "ELVTN", "RGDNS"], filterOpen: false, trigger: false, altered: false });
const [political, setPolitical] = useState({ id: 4, selected: true, data: undefined, code: ["CVLBT", "EQLTY", "PSFDM", "TCFDM", "IMPST", "PTRGT", "CRRPT", "ORGCM", "RPRST", "WAR", "LWORD", "SCRTY", "TRRSM"], filterOpen: false, trigger: false, altered: false });
const [developmental, setDevelopmental] = useState({ id: 5, selected: true, data: undefined, code: ["CHBTH", "DSESE", "HTHCR", "NTRTN", "SNTTN", "PVRTY", "EMPLT", "OPPTY", "NTECN", "ATTDC", "WMEDU", "HREDU", "EDUQT", "LTRCY"], filterOpen: false, trigger: false, altered: false });
const [social, setSocial] = useState({ id: 6, selected: true, data: undefined, code: ["DMPRS", "DVSTY", "LFEXP", "PPLTN", "URBNZ", "ETHTN", "RGPSC", "SXVNC", "THEFT", "VNTCM", "DMSTC", "DRGUS", "SUCDE", "TRFCK"], filterOpen: false, trigger: false, altered: false });
const [loading, setLoading] = useState(true);



const FULL_SORTED_LIST = ["ADHNC", "AGCLT", "ATTDC", "CHBTH", "CRRPT", "CTCHG", "CTRSK", "CVLBT", "DMPRS", "DMSTC", "DRGUS", "DSESE", "DVSTY", "EDUQT", "ELVTN", "EMPLT", "ENVHT", "EQLTY", "ETHTN", "GLBZT", "GSPAC", "HREDU", "HTHCR", "IMPST", "INFST", "LFEXP", "LTRCY", "LWORD", "NTECN", "NTRTN", "OPPTY", "ORGCM", "PBSVC", "PPLTN", "PSFDM", "PTRGT", "PVRTY", "RGDNS", "RGPSC", "RPRST", "RSCSC", "SCRTY", "SNTTN", "SUCDE", "SXVNC", "TCFDM", "TCHGY", "THEFT", "TRFCK", "TRRSM", "URBNZ", "VNTCM", "WAR", "WMEDU"];
const [currentGroupList, setCurrentGroupList] = useState(FULL_SORTED_LIST);

const GroupObject = {
  2: {
    codeLength: 2,
    order: 0,
    name: "Spiritual",
    codeOrigin: ["ADHNC", "GSPAC"],
    value: spiritual,
    actions: (props) => setSpiritual( prevState => ({ ...prevState, props }))
  },
  3:{
    codeLength: 11,
    order: 2,
    name: "Natural",
    codeOrigin: ["GLBZT", "INFST", "PBSVC", "TCHGY", "CTCHG", "CTRSK", "ENVHT", "RSCSC", "AGCLT", "ELVTN", "RGDNS"],
    value: natural,
    actions: (props) => setNatural(prevState => ({ ...prevState, props  }))
  }, 
  4:{
    codeLength: 13,
    order: 3,
    name: "Political",
    codeOrigin: ["CVLBT", "EQLTY", "PSFDM", "TCFDM", "IMPST", "PTRGT", "CRRPT", "ORGCM", "RPRST", "WAR", "LWORD", "SCRTY", "TRRSM"],
    value: political,
    actions: (props) => setPolitical(prevState => ({ ...prevState, props  }))
  },
  5:{
    codeLength: 14,
    order: 1,
    name: "Developmental",
    codeOrigin: ["CHBTH", "DSESE", "HTHCR", "NTRTN", "SNTTN", "PVRTY", "EMPLT", "OPPTY", "NTECN", "ATTDC", "WMEDU", "HREDU", "EDUQT", "LTRCY"],
    value: developmental,
    actions: (props) => setDevelopmental(prevState => ({ ...prevState, props  }))
  }, 
  6: {
    codeLength: 14,
    order: 4,
    name: "Social",
    codeOrigin: ["DMPRS", "DVSTY", "LFEXP", "PPLTN", "URBNZ", "ETHTN", "RGPSC", "SXVNC", "THEFT", "VNTCM", "DMSTC", "DRGUS", "SUCDE", "TRFCK"],
    value: social,
    actions: (props) => setSocial(prevState => ({ ...prevState, props  }))
  }
};


useEffect(() => {
  //mounted
  getGroupData().then(({ groups }) => {
    groups.forEach(group => {
      if(group.id !== 1 && group.id){
        const id = Number(group.id);
        const { value } = GroupObject[id];
        GroupObject[id].actions( value.data = group);
      }
    });
    if(spiritual.data !== undefined){
        setLoading(false)
    }
  });
}, []);




const groupCodes = spiritual?.code?.concat(natural?.code, political?.code, developmental?.code, social?.code)
const stringCodes = groupCodes.join()
const codes = stringCodes.replace(/^,|,$/g, '');

const [errorMessage, setErrorMessage] = React.useState(null);
const [groupSelected, setGroupSelected] = useState({ id: null, data: [], loading: true /* TODO: this loading is not only for group selected.  Need to change this. */ });
const [displayGroup, setDisplayGroup] = useState(false);

/********************************************** NEW COUNTRY BASED ON GROUPS SELECTED */





const GroupsArray = [
    spiritual,
    developmental,
    natural,
    political,
    social
  ];

  const GroupTrigger = useMemo(() =>  {
    const isFilterOpen = []
    Object.entries(GroupObject).forEach(([key, group]) => {
    const { value } = group;
      isFilterOpen.push(value.filterOpen);
    })
    return isFilterOpen.includes(true);
  });



  const GroupFilters = spiritual.filterOpen ? 0
    : developmental.filterOpen ? 1
    : natural.filterOpen ? 2
    : political.filterOpen ? 3
    : social.filterOpen ? 4
    : -1;

    const GroupAltered = spiritual.altered ? 0
    : developmental.altered ? 1
    : natural.altered ? 2
    : political.altered ? 3
    : social.altered ? 4
    : -1;

  const SwitchOffFilter = () => Object.entries(GroupObject).forEach(([key, group]) => {
    const { value, actions } = group;
    if(value.filterOpen === true){
      actions(  value.filterOpen = false );
    }
  });

 


/**********************************  Moved */
const handleGroupState = (id) => {
    const { value } = GroupObject[id];
    switch (Number(value.id)) {
      case Number(id):
        GroupObject[id].actions(value.filterOpen = !value.filterOpen);
      default:
        SwitchOffFilter();
    };
    setGroupSelected({ 
        id: id,
        loading: false,
        data: { group: GroupObject[id].value.data }, 
        filterOpen: !GroupObject[id].value.filterOpen
    });
  };

/*********************************************** Moved  */
  function removeOneItem(arr, value) {
    let index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };
  /*********************************************** Moved  */
  const removeCode = (arr, code) => {
    let j = 0;
    while (j < arr.length) {
      if (arr[j] === code) {
        arr.splice(j, 1);
      } else {
        ++j;
      };
    };
    return arr
  }
/*********************************************** Moved  */
  function addAndRemoveAllItems(arr, value) {
    let skip = false;
    for(let i = 0; i < value.length; i++){
      const found = arr.includes(value[i]);
      if(found){
        arr = removeCode(arr, value[i]);
        skip = true;
      }else if(!found && !skip){
        arr = [ ...arr, value[i]];
      };      
    };
    skip = false;
    return arr;
  }


/*********************************************** Moved  */
  function HandleSpiritualCodes(code, id){
      const { codeLength } = GroupObject[id];
      const spiritualCode = spiritual.code;
      const found = spiritualCode.includes(code);
      const missingOne = spiritualCode.length + 1 === codeLength 
      const newArray = (found) ? removeOneItem(spiritualCode, code) : spiritualCode.concat(code);
      const isSelected = newArray.length === 0;
      const isAltered = newArray.length === codeLength;
      setSpiritual(prevState => ({ ...prevState, code: newArray, selected: !isSelected, altered: !isAltered }));
    };

/*********************************************** Moved  */
  const HandleCodeSelection = (id, code) => {
      const { value, codeLength } = GroupObject[id]
      const found = value.code.includes(code);
      const newArray = removeOneItem(value.code, code);
      const isSelected = newArray.length === 0;
      const isAltered = newArray.length === codeLength;
      const missingOne = value.code.length + 1 === codeLength 
      switch (found) {
        case true:
          GroupObject[id].actions(  value.code = newArray, value.selected = !isSelected, value.altered = !isAltered );
          break;
        default:
          GroupObject[id].actions(  value.code = value.code.concat(code), value.selected = !isSelected, value.altered = !missingOne );
          break;
      };
  };

/*********************************************** Moved  */
  function handleCodes( id, code ){
    id = Number(id);
    switch (id) {
      case 2:
        HandleSpiritualCodes(code, id);
        break;
      default:
        HandleCodeSelection(id, code);
        break;
    };
  }


  /* Select and deselect a category */
/*********************************************** Moved  */
  const HandleAllCodes = (code, id) => {
      const { value, codeLength } = GroupObject[id];
      const newArray = addAndRemoveAllItems(value.code, code);
      const isSelected = newArray.length === 0;
      const isAltered = newArray.length === codeLength;
      GroupObject[id].actions(value.code = newArray, value.selected = !isSelected, value.altered = !isAltered );
  };

/*********************************************** Moved  */
  const CategoriesCode = (code, id) => {
    id = Number(id);
    switch (id) {
      case 2:
        setErrorMessage("at least one subcategory must be selected in this group");
        break;
      default:
        HandleAllCodes(code, id);
        break;
    };
  };

  const stratusClicked = () => {
    Object.entries(GroupObject).forEach(([key, group]) => {
      const { value, codeOrigin } = group;
       GroupObject[key].actions( value.selected = true, value.code = codeOrigin, value.filterOpen = false, value.trigger = false, value.altered = false );
    });
  };

  const HandleGroupDisplay = () => {
    if (displayGroup) {
      setDisplayGroup(false);
      if (groupSelected.filterOpen) {
        handleGroupState(groupSelected.id);
      }
    } else {
      setDisplayGroup(true);
    }
  };


 
  BuildingIndex(codes, currentGroupList, FULL_SORTED_LIST, groupCodes, GroupTrigger, setCurrentGroupList, GroupAltered == -1 )
    return <NavGroupContext.Provider value={{
        GroupObject: GroupObject,
        GroupsArray: GroupsArray,
        GroupFilters: GroupFilters,
        stratusIndex: GroupAltered == -1,
        groupSelected: groupSelected,
        errorMessage: errorMessage,
        loading: loading,
        codes: codes,
        groupCodes: groupCodes,
        currentGroupList: currentGroupList,
        FULL_SORTED_LIST: FULL_SORTED_LIST,
        GroupTrigger: GroupTrigger,
        displayGroup: displayGroup,
        GroupAltered: GroupAltered,
        actionNav: {
          setErrorMessage: setErrorMessage,
          stratusClicked: stratusClicked,
          handleGroupState: handleGroupState,
          handleCodes: handleCodes,
          CategoriesCode: CategoriesCode,
          setCurrentGroupList: setCurrentGroupList,
          HandleGroupDisplay: HandleGroupDisplay,
          setDisplayGroup: setDisplayGroup,
          SwitchOffFilter: SwitchOffFilter,
        }
      }}>
        {children}
    </NavGroupContext.Provider>;
};

const useNavGroup = () => React.useContext(NavGroupContext);

export {
  NavGroupContext,
  NavGroupConsumer,
  NavGroupProvider,
  useNavGroup
}