import Loading from "components/Loading/Loading";
import { ThemeProvider } from "lib/theme";
import React from "react";
import { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./internationalization/i18n";



ReactDOM.render(
  <ThemeProvider>
    <Suspense fallback={<Loading.FullScreen />}>
    <App />
  </Suspense>
  </ThemeProvider>,
  document.getElementById("root")
);
