import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../lib/authentication';

const ProtectedRoute = ({ unAuthorizedPath, ...props }) => {
    const { isAuthenticated } = useAuth();

    return (isAuthenticated
        ? <Route {...props} />
        : <Redirect to={ unAuthorizedPath } />
    );
};

export default ProtectedRoute;