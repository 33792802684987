import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Zoom({ altitude, globe }) {
  const history = useHistory();
  const { local } = useParams();
  const { i18n } = useTranslation("CountryExplorer");

  const handleZoom = (delta) => {
    if (delta === "in") {
      return globe.current.pointOfView({ altitude: altitude - 0.3 }, 3000);
      //actions.setGlobeView( prevState => ({ altitude: prevState.altitude - .4 }));
    } else if (delta === "out") {
      return globe.current.pointOfView({ altitude: altitude + 0.3 }, 3000);
      //actions.setGlobeView( prevState => ({ altitude:  prevState.altitude + .4 }))
    } else if (delta === "reset") {
      history.push(`/${local}/globe-explorer/`);
      return globe.current.pointOfView({ altitude: 1.45, lat: 25 }, 3000);
    }
  };
  
  {/* <!-- ZOOM in / out --> */}
  return (
             <div className="hidden 2xl:flex absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-8 flex-col justify-center">
                {/* <!-- plus & minus btns --> */}
                <div className="flex text-primary shadow-md dark:text-aqua overflow-hidden ZZborder ZZborder-aqua  ZZdark:border-mauve rounded-lg">
                  {/* <!-- plus --> */}
                  <div className="flex-1 px-5 py-2 bg-white border-r border-solid border-primary dark:bg-firefly ZZdark:border-mauve cursor-pointer"
                  onClick={() => handleZoom("in")}
                  >
                    <svg className="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.96 26.97">
                      <path d="M14.26,10.18H11.2v-3a.51.51,0,0,0-1,0v3.05h-3a.51.51,0,0,0,0,1h3.05v3.06a.51.51,0,0,0,1,0V11.2h3.06a.51.51,0,0,0,0-1Z"/>
                      <path d="M25.82,26.1l-7.56-7.86a10.77,10.77,0,1,0-.75.68l7.58,7.89a.52.52,0,0,0,.37.16.52.52,0,0,0,.36-.87ZM1,10.69a9.67,9.67,0,1,1,9.67,9.67A9.68,9.68,0,0,1,1,10.69Z"/>
                    </svg>
                  </div>
                  {/* <!-- minus --> */}
                  <div className="flex-1 px-5 py-2 bg-white dark:bg-firefly cursor-pointer"
                  onClick={() => handleZoom("out")}
                  >
                    <svg className="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.96 26.97">
                      <path d="M14.26,10.18H7.13a.51.51,0,0,0,0,1h7.13a.51.51,0,0,0,0-1Z"/>
                      <path d="M25.82,26.1l-7.56-7.86a10.77,10.77,0,1,0-.75.68l7.58,7.89a.52.52,0,0,0,.37.16.52.52,0,0,0,.36-.87ZM1,10.69a9.67,9.67,0,1,1,9.67,9.67A9.68,9.68,0,0,1,1,10.69Z"/>
                    </svg>
                  </div>
                </div>
                {/* <!-- reset --> */}
                {/* <!-- pointer-events --> */}
                <div className="pointer-events-auto flex items-center text-4xs text-gray-700 dark:text-gray-600 tracking-wide uppercase mt-2 mx-auto cursor-pointer"
                onClick={() => {
                  handleZoom("reset");
                }}
                >
                  <svg className="fill-current h-3 w-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.14 10.14">
                    <path d="M9.46,4.73h.67A5.07,5.07,0,1,1,1.48,1.49,4.93,4.93,0,0,1,5.15,0,5,5,0,0,1,8.79,1.61V0h.67V2.86H6.6V2.19H8.38A4.39,4.39,0,1,0,5.29,9.46,4.29,4.29,0,0,0,8.37,8,4.39,4.39,0,0,0,9.46,4.73Z"/>
                  </svg>
                  <p>{i18n.t("PageText.Reset Zoom", "Reset Zoom")}</p>
                </div>
              </div>
  );
};