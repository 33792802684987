import React, { useState, useEffect, useContext } from 'react';
import { loadGeoDataIndex, loadRankingData } from '../components/GlobeComponents/api';


import { buildRankingDataIndex, containsTrue, getRankingRange } from '../components/GlobeComponents/util';

const RankingDataContext = React.createContext();
const RankingDataConsumer = RankingDataContext.Consumer;

/**
 * @typedef {Object} RankingData
 * @property {objec}      rankingData: rankingData,
        mapCoordinates: mapCoordinates,
        geoDataIndex: geoDataIndex,
        actions: {
          setMapCoordinates: setMapCoordinates,
          setRankingData: setRankingData
        }
 */

/**
 * Context provider for Country ranking
 * @param {React.children} children
 * @returns {React.Context<RankingData>.Provider}
 */
const RankingDataProvider = ({ children }) => {
  // we really only need to pull, index, and store this data once, so let's get that
  // rolling in the background while the user's looking at the homepage
  const [geoDataIndex, setGeoDataIndex] = useState({});
  const [rankingData, setRankingData] = useState({ data: {}, loading: true, min: 1, max: 200 });

  const [mapCoordinates, setMapCoordinates] = useState({ display: 'countries', rankingDisplay: 'country' });
  

/* This Needs to be Moved */
  useEffect(() => {
    loadGeoDataIndex()
      .then(idx => {
        loadRankingData()
          .then(data => {
            // TODO: dedupe this
            const { min, max } = getRankingRange(data, 'countries');
            const rankIdx = buildRankingDataIndex(data, idx, true, max);
            setGeoDataIndex(idx);
            setRankingData(prevState => ({ ...prevState, data: rankIdx, loading: false, min, max }));
          });
      });
  }, []);

 
  return (
    <RankingDataContext.Provider
      value={{
        rankingData: rankingData,
        mapCoordinates: mapCoordinates,
        geoDataIndex: geoDataIndex,
        actions: {
          setMapCoordinates: setMapCoordinates,
          setRankingData: setRankingData
        }
      }}
    >
      {children}
    </RankingDataContext.Provider>
  );
};

const useRankingData = () => useContext(RankingDataContext);

export {
  RankingDataContext,
  RankingDataProvider,
  RankingDataConsumer,
  useRankingData
}