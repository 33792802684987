import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import preval from 'preval.macro';

export default function TimeStamp() {

    const [ time ] = useState({ Date: preval`module.exports = new Date().toLocaleString('en-US', { timeZone: 'America/Denver' });` });
///this is time
    const showLogin =  !!process.env.REACT_APP_INCLUDE_LOGIN_BUTTON && process.env.REACT_APP_INCLUDE_LOGIN_BUTTON === '1';
    const showTimestamp = !!process.env.REACT_APP_INCLUDE_LAST_MODIFIED_TIMESTAMP && process.env.REACT_APP_INCLUDE_LAST_MODIFIED_TIMESTAMP === '1';

    if (!showLogin && !showTimestamp) {
        return null;
    }

   /*  const TIME_STAMP_STYLE = {
        marginTop: 683,
        position: "absolute",
        left: 24
    } */
 
    return (
        <div style={{position:"absolute", backgroundColor:"black", top:"2px", fontSize:".6em", right:"10px", zIndex:999999, pointerEvents: "none", color:"yellow", opacity:"30%", pointerEvents: "none"}}>
            <div>
                {showTimestamp && (                    
                       <div>&nbsp;BUILD:&nbsp;{time.Date}&nbsp;</div>
                )}
                {showLogin && (
                    <Link
                        className="text-white inline-block bg-minestrone rounded-full px-7 py-3 transition-colors duration-200 ease-in-out shadow-md focus:outline-none focus:shadow-outline hover:bg-cranapple cursor-pointer mx-auto dark:bg-portage dark:hover:bg-portage-darker"
                        to="/login"
                    >
                        Login
                    </Link>
                )}
            </div>
        </div>
    );
}
