import React, { useEffect } from "react";
import { useRankingData } from "lib/rankingData";
import { isEmpty, isEqual } from "lodash";
import { loadRankingData } from "../GlobeComponents/api";
import { getRankingRange, buildRankingDataIndex } from "./util";

export default function BuildingIndex(
  codes,
  currentGroupList,
  FULL_SORTED_LIST,
  groupCodes,
  GroupTrigger,
  setCurrentGroupList,
  stratusIndex,
) {
  const { geoDataIndex, actions } = useRankingData();

  useEffect(() => {

    function build(tmpList) {
      if (!isEqual(currentGroupList, tmpList) && !GroupTrigger) {
        setCurrentGroupList(tmpList);
        actions.setRankingData(prevState => ({ ...prevState, loading: true }));
      }
    }
    let mounted = true;
    if (mounted) {
      const tmpList = groupCodes.sort();
      setTimeout(() => {
        build(tmpList);
      }, 1050);
      return () => {
        return (mounted = false);
      };
    }
  }, [groupCodes, stratusIndex]);

  useEffect(() => {
    let mounted = true;
    if (!isEmpty(geoDataIndex) && mounted) {
      const filtersClear = isEqual(FULL_SORTED_LIST, currentGroupList);
      loadRankingData(codes).then((data) => {
        const { min, max } = getRankingRange(data, "countries");
        const rankIdx = buildRankingDataIndex(
          data,
          geoDataIndex,
          filtersClear,
          max
        );
        actions.setRankingData((prevState) => ({
          ...prevState,
          data: rankIdx,
          isSelected: "countries",
          loading: false,
          min,
          max,
        }));
      });
      return () => {
        return (mounted = false);
      };
    }
  }, [geoDataIndex, currentGroupList]);
}
