import React, { useContext } from 'react';

export const ECTutorials = {
    None: 'none',
    RankingTutorial: 'ranking',
    DataTutorial: 'data',
    PeopleGroupsTutorial: 'ethnolinguistic',
}

const ECTutorialContext = React.createContext(ECTutorials.None);

const ECTutorialProvider = ECTutorialContext.Provider;
const ECTutorialConsumer = ECTutorialContext.Consumer;

const useECTutorialVisibility = () => useContext(ECTutorialContext);

export {
    useECTutorialVisibility,
    ECTutorialContext,
    ECTutorialProvider,
    ECTutorialConsumer
};
