import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as THREE from "three";
import find from 'lodash/find';
import values from 'lodash/values';
import ReactGlobe from 'react-globe.gl';
import Zoom from '../Zoom/Zoom'
import TimeStamp from '../TimeStamp';
import Loading from 'components/Loading/Loading';
import { speedy, tweenRevised, povThorough, recursiveTime, povSimple, recursiveChunked, log, setLog } from './Traveling'
import { useTranslation } from "react-i18next";
import { useZoom } from "../Context/ZoomContext";
import { useGlobe } from '../Context/GlobeContext';
import { useNavGroup } from '../Context/NavGroupContext';

const opacityForZoom = (zoom, stateIsDark) => {
  const lookup = [
    { min: 1.45, light: 1, dark: 1 },
    { min: 1.25, light: .90, dark: .87 },
    { min: 1.05, light: .80, dark: .74 },
    { min: .85, light: .70, dark: .61 },
    { min: .65, light: .60, dark: .48 },
    { min: .45, light: .50, dark: .35 },
    { min: 0, light: .40, dark: .22 }
  ]
  let opLevel = find(lookup, o => { return zoom >= o.min })
  if(!opLevel) opLevel = lookup[lookup.length - 1]
  return stateIsDark ? opLevel.dark : opLevel.light
}

export default function Globe({ 
  rankingData,
  ccrData, 
  currentDataScope, 
  stateIsDark, 
  isModalOpen, 
  currentTarget,
}) {
  const { stratusIndex } = useNavGroup();
  const { coords, actions} = useZoom()
  const { setCoords } = actions;
  const { actionsGlobe, globe } = useGlobe()
  const [traveling, setTraveling] = useState(false);
  const { i18n } = useTranslation();
  const { local } = useParams();
  const history = useHistory();

  const zoomNumber = Math.round(coords.altitude * 100) / 100;
  const opacity = opacityForZoom(zoomNumber, stateIsDark)

  const globeMaterial = useMemo(() => new THREE.MeshPhongMaterial({
    color: (stateIsDark) ? new THREE.Color("rgb(19, 60, 87)") : new THREE.Color("rgb(179, 217, 243)")
  }), [stateIsDark]);

  useEffect(() => {
    let mounted = true;
    if(mounted){
      if (!isModalOpen) {
        if (currentTarget !== null)
          gotoCountry(currentTarget?.isoA3);
      }
      return () => {
        return mounted = false;
      }
    };
  }, [isModalOpen, currentTarget]);

  useEffect(() => {
    let mounted = true;
    if(mounted){
        const globeScene = globe.current.scene().children;
      if (globeScene[2] != undefined) {
        const intensity = (stateIsDark) ? 0.6 : 0.3;
        globeScene[2].color = { r: 0.53, g: 0.94, b: 0.93 }
        globeScene[2].position.setY(0);
        globeScene[2].position.setZ(1);
        globeScene[2].intensity = intensity;
      }
      return () => {
        return mounted = false;
      }
    };
  }, [coords.altitude]);

  /* polygon data set callback */
  const loadBorders = React.useMemo(() => {
    const dataSet = ccrData.loading ? [] : values(ccrData.data[currentDataScope])
    window.dLog('rendering with dataSet:' + JSON.stringify(dataSet), 8);
    return dataSet;
  }, [ccrData, ccrData, currentDataScope]);


  /* polygon color callback */
  const featureColor = React.useCallback((feature) => {
    const colorSpec = feature.color[stateIsDark];
    const colorSpecType = typeof colorSpec;
    let res;
    if (ccrData.loading) {
      res = 'rgba(245, 245, 220, 0)';
    } else if (colorSpecType === 'string') {
      res = colorSpec;
    } else if (colorSpecType === 'object') {
      res = `rgb(${colorSpec})`;
    } else {
      res = 'rgb(245, 245, 220)';
    }
    return res;
  }, [ccrData, currentDataScope, stratusIndex, opacity]);



  /* polygon hover callback */
  const globeHover = React.useCallback((feature, skipHover) => {
    if (ccrData.loading || feature == undefined || skipHover) {
      return null;
    }
    window.dLog('feature:' + feature.name, 8);
    const bgColor = featureColor(feature);
    const labelName = i18n.t(`Countries.${feature.name}`, feature.name);
    const labelRank = feature.rank;
    

    return (`
      <div class="hidden 2xl:block w-64 bg-white rounded-lg shadow-md px-4 py-1 mx-auto relative dark:bg-firefly ">
        <div class="flex items-center">

          <div class="flex items-center mr-auto">
            <svg class="fill-current text-primary h-6 w-6 mr-2 dark:text-aqua" viewBox="0 0 23.95 23.95">
              <g>
                <path d="M23.2,11.23H21.92A10,10,0,0,0,12.72,2V.75a.75.75,0,1,0-1.49,0V2A10,10,0,0,0,2,11.23H.75a.75.75,0,1,0,0,1.49H2a10,10,0,0,0,9.21,9.2V23.2a.75.75,0,1,0,1.49,0V21.92a10,10,0,0,0,9.2-9.2H23.2a.75.75,0,1,0,0-1.49ZM12.72,20.42v-.71a.75.75,0,1,0-1.49,0v.71a8.48,8.48,0,0,1-7.7-7.7h.71a.75.75,0,1,0,0-1.49H3.53a8.46,8.46,0,0,1,7.7-7.7v.71a.75.75,0,1,0,1.49,0V3.53a8.48,8.48,0,0,1,7.7,7.7h-.71a.75.75,0,1,0,0,1.49h.71A8.5,8.5,0,0,1,12.72,20.42Z"/>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4.49a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,13.47Z"/>
              </g>
            </svg>
            <span class="uppercase text-gray-800 text-sm dark:text-white">${labelName}</span>
          </div>

          <div class="flex h-10 w-10 bg-minestrone p-1 mr-2 rounded-full dark:bg-portage" style="background-color: ${bgColor}">
            <span class="text-white text-md m-auto">${(labelRank == null) ? "N/A" : labelRank}</span>
          </div>
        </div>
      </div>
    `)

  }, [ccrData]);


  /* ******************************************************* */
  /* ******************************************************* */

  const handleAnimationComplete = () => setTraveling(false);

  // thin wrapper to determine which globe trotting function to use
  async function gotoCountry(countryIso){
    if (!countryIso) return;
    const country = rankingData.data.countries[countryIso];
    log({ lf:1, status: ' => gotoCountry called', countryIso, country })
    // stop spinning & disable hovers
    actionsGlobe.handleRotation(false);
    setTraveling(true);

    const defaultFunc = speedy; // tweenRevised;
    // update this if you want Firefox & Safari to use a different default
    const geckoFunc = speedy; //povThorough;
    const isGecko = /Gecko/.test(window.navigator.userAgent);
    const travelFunc = window.travelFunc ? window.travelFunc : isGecko ? geckoFunc : defaultFunc;
    travelFunc(country, globe, handleAnimationComplete);
  }

  /* ******************************************************* */
  /* ******************************************************* */

  const globeAction = (el) => {
    if (currentTarget?.id !== el.id && el.id != null) {
      history.push(`/${local}/globe-explorer/${el.isoA3}`);
    } else if (currentTarget?.id === el.id && el.id != null) {
      history.push(`/${local}/country-explorer/${el.isoA3}`);
    }
  };

  let intFrameWidth = window.innerWidth;
  let intFrameHeight = window.innerHeight;
  window.dLog(intFrameWidth, 10);
  let polygonAltitude = 0.01;
  if (/Chrome/.test(window.navigator.userAgent))
    polygonAltitude = .0077;

  return (
    <>
    <div className="w-full h-full object-cover" style={{ marginTop: (intFrameWidth > 560) ? '0px' : '80px' }} >
        <TimeStamp />
        <ReactGlobe
          ref={globe}
          animateIn={true}
          globeMaterial={globeMaterial}
          showAtmosphere={false}
          width={intFrameWidth}
          height={(intFrameWidth < intFrameHeight) ? intFrameWidth : intFrameHeight}
          backgroundColor={(stateIsDark) ? "#161616" : "#f6faff"}
          waitForGlobeReady={true}
          polygonsData={loadBorders}
          polygonCapColor={featureColor}
          polygonSideColor={() => "rgba(245, 245, 220, 0)"}
          polygonStrokeColor={() => (zoomNumber <= 1) ? "rgba(245, 245, 220, .5)" : "rgba(245, 245, 220, 0)"}
          polygonLabel={(d) => globeHover(d, traveling)}
          lineHoverPrecision={.1}
          onGlobeClick={() => actionsGlobe.handleRotation(false)}
          polygonCapCurvatureResolution={() => 5}
          onPolygonClick={(el, ev) => globeAction(el)}
          polygonAltitude={() => polygonAltitude}
          polygonsTransitionDuration={0}
          onZoom={z => {
            return setCoords({ longitude: z.lng, latitude: z.lat, altitude: z.altitude })
          }
          }
        />
        <Loading visible={ccrData.loading} className="absolute top-0 left-0 w-full h-full opacity-50" size="lg" />
      </div>
      <Zoom altitude={coords.altitude} globe={globe} />
    </>
  )
}
