import React, { useContext } from "react";
import { fetchCountriesWithIndicators } from "../api";

const IndicatorCountryListContext = React.createContext(null);
const IndicatorCountryListConsumer = IndicatorCountryListContext.Consumer;

function IndicatorCountryListProvider({ children }) {
  const [indicatorCountryList, setIndicatorCountryList] = React.useState({
    countries: [],
    loading: true,
  });

  function getCountryList(indicatorId) {
    if(indicatorId === undefined) return;
    if(!indicatorCountryList.loading) setIndicatorCountryList(prevState => ({ countries: prevState.countries, loading: true }))
      fetchCountriesWithIndicators(indicatorId).then((res) => {
        setIndicatorCountryList({ countries: res.countries, loading: false })
      });
  }

  return (
    <IndicatorCountryListContext.Provider
      value={{
        indicatorCountryList,
        actionIndicatorCountryList: {
          getCountryList,
        },
      }}
    >
      {children}
    </IndicatorCountryListContext.Provider>
  );
}

const useIndicatorCountryList = () => useContext(IndicatorCountryListContext);

export {
  IndicatorCountryListContext,
  IndicatorCountryListConsumer,
  IndicatorCountryListProvider,
  useIndicatorCountryList,
};
