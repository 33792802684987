import countryColors from './countryColors.json';

export const ApiEndpoints = {
    geoData: "/geoJsonMinify.json",
    stratusApi: process.env.REACT_APP_API_URL || "https://api.stratus.earth/api",
  }
  
export const ColorMap = countryColors;

export const groupsArr = [ {name: "Spiritual", id: 2 }, {name: "Developmental", id: 5 }, {name: "Natural", id: 3 }, {name: "Political", id: 4 }, {name: "Social", id: 6 } ]
