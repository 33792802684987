import each from 'lodash/each';
import set from 'lodash/set';
import TWEEN from '@tweenjs/tween.js';
import * as d3 from "d3";
import LatLon from 'geodesy/latlon-spherical';

/* ******************************************************* *
 * To test/evaluate the different functions, follow these
 * steps:
 * 
 * 1. Set TESTING to true below, wait for refresh
 * 
 * 2. In the console, you'll see the five available functions
 *    listed by name (brief descriptions at the end of this
 *    comment block):
 *      * tweenRevised
 *      * povThorough
 *      * recursiveTime
 *      * povSimple
 *      * recursiveChunked
 * 
 * 3. Indicate which function to use as follows:
 *      travelFunc = <function name>
 * 
 *    ...then click another country in the globe select list,
 *    and see how it does. The console will tell you which
 *    function is being used, as well as give you lots of
 *    other useless info.
 * 
 *    The gotoCountry() wrapper method in Globe checks if
 *    travelFunc is set in the console first before calling
 *    its own default, so you can easily swap that around to
 *    compare behavior and performance.
 * 
 *    Once you're done testing just update the default(s) in
 *    the gotoCountry wrapper method accordingly, and then
 *    set TESTING to false here to disable all the logging
 *    output.
 * 
 * Function descriptions:
 * 
 *   * tweenRevised
 *     - current default, essentially pre-existing approach
 * 
 *   * povThorough
 *     - a riff on Ryan J's pass on the FFX fix, just sending
 *       in more thorough data arrays to try to lean into 
 *       the right interpolation and easing models. The 
 *       pointOfView function itself uses Tween internally
 *       but a) without any interpolation, b) with a 
 *       dumb easing model, and c) without any way to 
 *       override either one
 * 
 *   * recursiveTime
 *     - a Promise-based recursive attempt using time
 * 
 *   * povSimple
 *     - Ryan J's first pass on the FFX fix; slightly
 *       modified to get rid of the startup delay, but
 *       comments above regarding the built-in use of
 *       Tween still apply
 * 
 *   * recursiveChunked
 *     - a Promise-based recursive attempt using distance
 *       chunks; easily the worst by far, included
 *       only for the amusement of posterity
 * 
 * ******************************************************* */
const TESTING = false;

/**
 * simple linear scaling function to pick a max height value given
 * a certain distance between countries
 */
const arcHeightScale = d3.scaleLinear()
  .domain([0, 3000, 5000, 10000])
  .range([.4, .5, 1, 1.5])
  .clamp(true)

/**
 * Returns the altitude to use for the midpoint of the arc,
 * though when using e.g. Bezier interpolation this is never reached;
 * it just defines the outer point for forming the curve
 */
const arcHeightForDistance = (currentAltitude, distance) => {
  // if I'm already high up, just use the current altitude
  // (avoids the close-but-high weirdness)
  return currentAltitude > .75 ? currentAltitude : arcHeightScale(distance)
}

/**
 * Returns a hash containing start, mid, and end geographic points from the current
 * location to the given country, plus calculated distance and resulting duration
 */
const geoParams = (country, globe) => {
  const t0 = performance.now()
  const durationBase = 4000;
  const start = globe.current.pointOfView()
  const startGeo = LatLon.parse(start)
  const endGeo = LatLon.parse(country)
  const distance = startGeo.distanceTo(endGeo) / 1000
  const maxArcHeight = arcHeightForDistance(start.altitude, distance)
  const duration = maxArcHeight * durationBase;
  // thought this was handy but his "mid point" algorithm takes the shortest 
  // route, which sometimes takes you over the poles and sets the globe at
  // angles that feel off...
  // const midGeo = startGeo.midpointTo(endGeo)
  const midGeo = {
    lat: (startGeo.lat + endGeo.lat) / 2,
    lng: (startGeo.lng + endGeo.lng) / 2
  }
  const t1 = performance.now()
  log({
    status: '...geographic parameters determined:',
    startGeo, midGeo, endGeo, distance, maxArcHeight, durationBase, duration, time: xec(t0, t1)
  })

  const mid = { lat: midGeo.lat, lng: midGeo.lng, altitude: maxArcHeight }
  const end = { lat: endGeo.lat, lng: endGeo.lng, altitude: .4 }
  return { start, startGeo, mid, midGeo, end, endGeo, distance, duration }
}

/**
 * Travel using the existing tween algorithms
 * 
 * for reference:
 *    easing models: http://sole.github.io/tween.js/examples/03_graphs.html
 *    interpolation models: http://tweenjs.github.io/tween.js/examples/06_array_interpolation.html
 */
export const tweenRevised = (country, globe, onComplete) => {
  log({ lf:1, status: ' => tweenRevised() called, heading for:', country })
  const { start, mid, end, duration } = geoParams(country, globe)

  const latArr = [start.lat, mid.lat, end.lat]
  const lngArr = [start.lng, mid.lng, end.lng]
  const altArr = [start.altitude, mid.altitude, end.altitude]

  const t0 = performance.now()
  const flyToDest = new TWEEN.Tween(start).to({
    lat: latArr, lng: lngArr, altitude: altArr
  }, duration)      
    .interpolation(TWEEN.Interpolation.Bezier)
    .easing(TWEEN.Easing.Sinusoidal.InOut)
    .onUpdate(globe.current.pointOfView)
    .onComplete(() => {
      const t1 = performance.now()
      log({ status: '...Complete.', time: xec(t0, t1) })
      if (onComplete) onComplete()
    });

  log({ lf:1, status: 'Starting tweenRevised animation loop...' })
  flyToDest.start();
}

/**
 * Travel using globe's own internal animation algorithm, but using
 *  tween's easing and interpolations to build out more comprehensive arrays
 */
export const speedy = (country, globe, onComplete) => {
  log({ lf:1, status: ' => speedy() called, heading for:', country })
  const { start, mid, end, distance, duration } = geoParams(country, globe)
  globe.current.pointOfView({
    lat: end.lat,
    lng: end.lng
  }, 1000);
}

export const povThorough = (country, globe, onComplete) => {
  log({ lf:1, status: ' => povThorough() called, heading for:', country })
  const { start, mid, end, distance, duration } = geoParams(country, globe)
  const newDuration = duration * 1.5 // the pov approaches seem to fly a bit too fast...

  const perTick = 50 // magic number
  const chunks = newDuration / perTick
  const slots = Math.ceil(chunks)
  const perChunk = distance / chunks

  const x2 = performance.now()
  const easingFunc = TWEEN.Easing.Sinusoidal.InOut
  const interpolationFunc = TWEEN.Interpolation.Bezier
  const latRange = [start.lat, mid.lat, end.lat]
  const lngRange = [start.lng, mid.lng, end. lng]
  const altRange = [start.altitude, mid.altitude, end.altitude]
  const latArr = []
  const lngArr = []
  const altArr = []
  let progress = 0
  let easedPct = 0
  for (let idx = 0; idx < slots; idx++) {
      progress += perChunk
      easedPct = easingFunc(progress / distance)
      // progressPoint = startGeo.intermediatePointTo(endGeo, easedPct)
      latArr.push(interpolationFunc(latRange, easedPct))
      lngArr.push(interpolationFunc(lngRange, easedPct))
      altArr.push(interpolationFunc(altRange, easedPct))
  }
  const x3 = performance.now()
  log({ status: '...steps precalculated:', latArr, lngArr, altArr, time: xec(x2, x3) })

  log({ lf:1, status: 'Starting povThorough animation loop...' })
  globe.current.pointOfView({
    lat: latArr,
    lng: lngArr,
    altitude: altArr,
  }, newDuration);
  setTimeout(() => {
    const x4 = performance.now()
    log({ status: '...Complete, roughly', time: xec(x3, x4) })
    if (onComplete) onComplete()
  }, newDuration)
}

/**
 * Travel using a rather garbage attempt at force-spacing animation steps
 * @param {object} country 
 * @param {object} globe 
 * @param {function} onComplete - onComplete callback
 */
export const recursiveTime = (country, globe, onComplete) => {
  log({ lf:1, status: ' => recursiveTime() called, heading for:', country })
  const { start, startGeo, mid, end, endGeo, distance, duration } = geoParams(country, globe)

  const latRange = [start.lat, mid.lat, end.lat]
  const lngRange = [start.lng, mid.lng, end.lng]
  const altRange = [start.altitude, mid.altitude, end.altitude]

  const x2 = performance.now()
  const easingFunc = TWEEN.Easing.Sinusoidal.InOut
  const interpolationFunc = TWEEN.Interpolation.Bezier
  const startTime = performance.now()
  let easedShift = 0
  let tick = startTime
  let tickSpan = 0
  let elapsed = 0
  let progressLat, progressLng, progressAltitude

  async function moveIt(time) {
    const keepGoing = await new Promise((resolve) => {
      if (time.elapsed >= duration) {
        resolve(false);
      } else {
        time.easedShift = easingFunc(time.elapsed / duration)
        log({ ...time })
        // progressPoint = startGeo.intermediatePointTo(endGeo, time.easedShift)
        progressLat = interpolationFunc(latRange, time.easedShift)
        progressLng = interpolationFunc(lngRange, time.easedShift)
        progressAltitude = interpolationFunc(altRange, time.easedShift)
        globe.current.pointOfView({ lat: progressLat, lng: progressLng, altitude: progressAltitude }, 600) // time.tickSpan || 100)
        // a timeout of zero still forces a cycle to evaluate whether the time's elapsed, otherwise non5
        // of the animation even happens; you just wait a couple seconds and snap to the new target
        setTimeout(() => resolve(true), 1) // time.tickSpan || 0.01);
      }
    });
    if (keepGoing) {
      time.tick = performance.now()
      time.tickSpan = time.tick - startTime - time.elapsed
      time.elapsed += time.tickSpan
      moveIt(time);
    } else {
      const x3 = performance.now()
      log({ status: '...Complete, roughly', time: xec(x2, x3) })
      if (onComplete) onComplete()
    }
  }

  log({ lf:1, status: 'Starting recursiveTime animation loop...'})
  moveIt({ easedShift, tick, tickSpan, elapsed })
}

/**
 * Travel using globe's own internal animation algorithm with a simple approach
 */
export const povSimple = (country, globe, onComplete) => {
  log({ lf:1, status: ' => povSimple() called, heading for:', country })
  const { mid, end, duration } = geoParams(country, globe)
  const newDuration = duration * 1.5 // the pov approaches seem to fly a bit too fast...

  // don't actually want to include start in this one;
  // turns out that's what makes it seem to wait too long to start
  // given the cubic easing model they're using internally
  const latArr = [mid.lat, end.lat]
  const lngArr = [mid.lng, end.lng]
  const altArr = [mid.altitude, end.altitude]

  const t0 = performance.now()
  log({ lf:1, status: 'Starting povSimple animation loop...'})
  globe.current.pointOfView({
    lat: latArr, lng: lngArr, altitude: altArr
  }, newDuration);
  setTimeout(() => {
    const t1 = performance.now()
    log({ status: '...Complete, roughly.', time: xec(t0, t1) })
    if (onComplete) onComplete()
  }, newDuration)
}

/**
 * Travel using a rather garbage attempt at force-spacing animation steps;
 * the end result runs far too slowly no matter how I tweak it.
 */
export const recursiveChunked = (country, globe, onComplete) => {
  log({ lf:1, status: ' => recursiveChunked() called, heading for:', country})
  const { start, startGeo, mid, end, endGeo, distance, duration } = geoParams(country, globe)

  const x2 = performance.now()
  const perTick = 50 // magic number
  const chunks = duration / perTick
  const slots = Math.ceil(chunks)
  const perChunk = distance / chunks

  const latArr = [start.lat, mid.lat, end.lat]
  const lngArr = [start.lng, mid.lng, end.lng]
  const altArr = [start.altitude, mid.altitude, end.altitude]
  const x3 = performance.now()
  log({ status: '...geometric parameters determined:', perTick, chunks, slots, perChunk, time: xec(x2, x3) })
  
  const easingFunc = TWEEN.Easing.Sinusoidal.InOut
  const interpolationFunc = TWEEN.Interpolation.Bezier
  const collector = new Array(slots)
  let progress = 0
  let easedPct = 0
  let progressLat, progressLng, progressAltitude
  for (let idx = 0; idx < slots; idx++) {
      progress += perChunk
      easedPct = easingFunc(progress / distance)
      // progressPoint = startGeo.intermediatePointTo(endGeo, easedPct)
      progressLat = interpolationFunc(latArr, easedPct)
      progressLng = interpolationFunc(lngArr, easedPct)
      progressAltitude = interpolationFunc(altArr, easedPct)
      collector[idx] = { lat: progressLat, lng: progressLng, altitude: progressAltitude }
  }
  const x4 = performance.now()
  log({ status: '...steps precalculated:', collector, time: xec(x3, x4) })

  async function moveIt(idx) {
    const newIdx = await new Promise((resolve) => {
      if (idx >= collector.length) {
        resolve();
      } else {
        globe.current.pointOfView(collector[idx]);
        setTimeout(() => resolve(idx + 1), 0);
      }
    });
    if (newIdx) {
      moveIt(newIdx);
    } else {
      const x5 = performance.now()
      log({ status: '...Complete, finally', time: xec(x4, x5) })
      if (onComplete) onComplete()
    }
  }

  log({ lf:1, status: 'Starting recursiveChunked animation loop, with apologies...'})
  moveIt(0)
}

/* *******************************************************
 *  Utility logging functions
 */

/* takes two timestamps, returns a descriptive string showing the difference */
export const xec = (x, y) => {
    const ms = (y - x).toFixed(3)
    const s = (ms / 1000.0).toFixed(3)
    return s + 'sec (' + ms + 'ms )'
}

/*
* simple key-value console logger; send it a hash, watch the magic
* e.g.:
*  const t0 = performance.now()
*  const foo = 13;
*  const blah = 'some random string';
*  const xyz = objectInstanceVar;
*  const t1 = performance.now()
* 
*  log({ foo, blah, xyz, timing: xec(t0, t1) })
*/
export const log = (props, doSet = false) => {
  if (!TESTING) return;
  const b = 'font-weight: bolder;'

  each(props, (v, k) => {
    if (k === 'lf') console.log(' ')
    else {
      if (doSet) set(window, k, v)
      console.log(
        `%c${k}`, b, ':',
        (typeof v === 'function' ? 'f ()' : v)
      )
    }
  })
}

/*
* Call this instead of log() to set the hash keys as properties on the window object.
* Useful for testing/ad-hoc tinkering, but obviously use with care
*/
export const setLog = (props) => log(props, true)

// put these in the console space for easy test/swap access
if (TESTING) setLog({ travelFunc: null, tweenRevised, povThorough, recursiveTime, povSimple, recursiveChunked})

/* ******************************************************* */

/*
React.useEffect(() => {
  if (rankingData?.data?.countries) {
    const mali = rankingData.data.countries.MLI
    const niger = rankingData.data.countries.NER
    const ethio = rankingData.data.countries.ETH
    const india = rankingData.data.countries.IND
    const usa = rankingData.data.countries.USA

    const setCountry = (country) => {
      actions.handleRotation(false)
      globe.current.pointOfView({
        lat: country.latitude,
        lng: country.longitude,
        altitude: .4
      })
    }

    setLog({ mali, niger, ethio, india, usa, setCountry, LatLon, TWEEN })
  }
}, [rankingData])
*/
