import React, { Suspense } from "react";
import CodeSplitting from '../CodeSplitting';

const ResetZoom = React.lazy(() => import("../Zoom/ResetZoom"));
const Switch = React.lazy(() => import("../Switch/Switch"));
const YourFocusMobile = React.lazy(() =>
  import("../YourFocus/YourFocusMobile")
);
const SearchFilter = React.lazy(() => import("../SearchFilter/SearchFilter"));
const Ranking = React.lazy(() => import("../Ranking/Ranking"));

// import ResetZoom from "../Zoom/ResetZoom";
// import Switch from "../Switch/Switch";
// import YourFocusMobile from "../YourFocus/YourFocusMobile";
// import SearchFilter from "../SearchFilter/SearchFilter";
// import Ranking from "../Ranking/Ranking";



export default function GlobeBody({
  currentTarget,
  globe,
  handleModeChange,
  stateIsDark,
  rankingData,
  HandleOverlay,
  isModalOpen,
  currentDataScope,
}) {
  const noTargetClass = currentTarget ? "" : " flex ";
  const noTargetSwitchClass = currentTarget ? "" : " order-1 flex-grow-8 ";

  return (
    <section className="pointer-events-changed relative 2xl:mt-10">
      <div className="md:container">
        <div className={"2xl:hidden " + noTargetClass}>
          {/* <!-- reset --> */}
          <CodeSplitting
            visible={true}
            globe={globe}
            checker={globe.current}
            classes="absolute top-0 left-0 w-full h-full opacity-50"
            size="lg"
          >
            <ResetZoom globe={globe} currentTarget={currentTarget} />
          </CodeSplitting>
          {/* <!-- MODES: day-night / FOCUS--> */}
          <div
            className={
              "flex items-center sm:justify-between " + noTargetSwitchClass
            }
          >
            {/* <!-- modes --> */}
            <CodeSplitting
              visible={true}
              globe={globe}
              checker={globe.current}
              classes="absolute top-0 left-0 w-full h-full opacity-50"
              size="lg"
            >
              <Switch
                display="mobile"
                handleModeChange={handleModeChange}
                currentTarget={currentTarget}
              />
            </CodeSplitting>
            {/* <!-- focus --> */}
            <CodeSplitting
              visible={true}
              globe={globe}
              checker={globe.current}
              classes="absolute top-0 left-0 w-full h-full opacity-50"
              size="lg"
            >
              {currentTarget !== null && (
                <YourFocusMobile
                  currentTarget={currentTarget}
                  stateIsDark={stateIsDark}
                  ccrData={rankingData}
                  currentDataScope={currentDataScope}
                />
              )}
            </CodeSplitting>
          </div>
        </div>
        {/* <!-- SEARCH bar: on desktop --> */}
        <CodeSplitting
          visible={true}
          globe={globe}
          checker={globe.current}
          classes="absolute top-0 left-0 w-full h-full opacity-50"
          size="lg"
        >
          <SearchFilter
            currentDataScope={currentDataScope}
            display="globe-desktop"
            HandleOverlay={HandleOverlay}
          />
        </CodeSplitting>
        {/* <!-- GRADIENT --> */}
        {/* Ranking Country List */}
        <div className="lg:flex mt-5 2xl:flex-col-reverse 2xl:items-end">
        <CodeSplitting
          visible={true}
          globe={globe}
          checker={globe.current}
          classes="absolute top-0 left-0 w-full h-full opacity-50"
          size="lg"
        >
          <Ranking
            skipAnimationProp={isModalOpen}
            isModalOpen={isModalOpen}
            ccrData={rankingData}
            currentDataScope={currentDataScope}
            prefix="globe"
          />
        </CodeSplitting>
        </div>
      </div>
    </section>
  );
}
