import React, { useEffect, useState, useCallback, useContext } from 'react';

const BreakPointMatchesContext = React.createContext({});

const BreakPointMatchesProvider = ({ sizes, children }) => {

    const [matches] = useState({
        ...Object.keys(sizes)
            .map((sizeName) => [sizeName, sizes[sizeName]])
            .sort(([, sizePxA], [, sizePxB]) => sizePxB - sizePxA)
            .reduce((prevSizes, [sizeName, size]) => ({
                ...prevSizes,
                [sizeName]: window.matchMedia(`(min-width: ${size}px)`)
            }), {})
    });

   // console.log('RENDER BP MATCHES!!!!!');

    return (
        <BreakPointMatchesContext.Provider value={{ matches, sizes }}>
            {children}
        </BreakPointMatchesContext.Provider>
    );
}

const BreakPointContext = React.createContext({});



const BreakPointContextProvider = ({ children }) => {
    const { matches, sizes } = useContext(BreakPointMatchesContext);
    const [currentSizes, setCurrentSizes] = useState(
        Object.keys(matches)
            .filter(sizeName => matches[sizeName].matches)
            .map(sizeName => sizes[sizeName])
            .sort((a, b) => a - b)
    );

    useEffect(() => {

        const breakPointListeners = [];
        Object.keys(matches).forEach((sizeName) => {
            const size = sizes[sizeName];
            matches[sizeName]
            const onSizeChange = (event) => {
                setCurrentSizes(prevCurrentSizes => {
                    let newCurrentSizes = prevCurrentSizes;

                    const sizeIndex = prevCurrentSizes.indexOf(size);
                    if (event.matches) {
                        if (sizeIndex == -1) {
                            newCurrentSizes = [...prevCurrentSizes, size];
                        }
                    } else if (sizeIndex > -1) {
                        newCurrentSizes = [...newCurrentSizes.slice(0, sizeIndex), ...newCurrentSizes.slice(sizeIndex + 1)];
                    }
                    newCurrentSizes.sort((a, b) => a - b);
                    return newCurrentSizes;
                });
            };

            matches[sizeName].addEventListener('change', onSizeChange);
            breakPointListeners.push([matches[sizeName], onSizeChange]);
        });

        return function cleanup() {
            breakPointListeners.forEach(([mediaQueries, listener]) => {
                mediaQueries.removeEventListener('change', listener);
            });
        }
    }, [matches, sizes, setCurrentSizes]);

    const isAtLeastSize = useCallback((sizeName) => {
        const size = sizes[sizeName];
        const lastSize = currentSizes.length > 0 ? currentSizes[currentSizes.length - 1] : 0;
        return lastSize >= size;
    }, [currentSizes, sizes]);

    return (
        <BreakPointContext.Provider value={{ isAtLeastSize, currentSizes, sizes }}>
            {children}
        </BreakPointContext.Provider>
    );
}

const BreakPointProvider = ({ sizes, children }) => (
    <BreakPointMatchesProvider sizes={sizes}>
        <BreakPointContextProvider>
            {children}
        </BreakPointContextProvider>
    </BreakPointMatchesProvider>
);

const useBreakPoints = () => useContext(BreakPointContext);

export {
    useBreakPoints,
    BreakPointProvider
}
