import _, { max } from 'lodash';
import md5 from 'md5'
import { createApolloFetch } from 'apollo-fetch';
import * as API from './GraphqlQuery.js';
import { ApiEndpoints } from './constants';

const fetchStratus = createApolloFetch({ uri: ApiEndpoints.stratusApi });
fetchStratus.use(({ request, options }, next) => {
  const token = localStorage.getItem('token');
  if (token) {
    if (!options.headers) {
      options.headers = {};
    }
    options.headers['authorization'] = token;
  }

  next();
});

/**
 * @typedef {[number, number]} LatLongTuple
 */

/**
 * @typedef {Object} CountryGeometry
 * @property {'Polygon'|'MultiPolygon'} type The type of the `coordinates` object.
 * @property {LatLongTuple[]|LatLongTuple[][]} coordinates The coordinate array of the polygon(s).  If the type is
 *                                                                     'MultiPolygon' then an array of polygons is presented
 *                                                                      where a 'Polygon' is a single array of coordinate pairs
 */

/**
 * @typedef {Object} GeographicData
 * @property {geometry} CountryGeometry
 * @property {object} properties
 * @property {string} properties.sovereignt If the Country is sovereign, this is the country name otherwise this country depends on `sovereignt`
 * @property {'Sovereign country'|'Dependency'|'Country'|'Indeterminate'|'Disputed'} [properties.type] If the country is sovereign
 * @property {string} properties.admin Country name with no abbreviations
 * @property {string} properties.name
 * @property {string} properties.iso_a3 ISO 3166-1 alpha-3 country abbreviation
 * @property {string} properties.continent
 * @property {string} properties.region_un
 * @property {string} properties.region_wb Region according to The World Bank
 * @property {string} properties.subregion
 * @property {'Admin-0 country'} properties.featureclass
 * @property {'Feature'} type
 */

/**
 * Queries the API for geographic data of all known countries.
 * 
 * @returns {Promise<Object<string, GeographicData>>} Geographic data structured as a hash (object), keyed by country ISO code
 * 
 * @example
 * {
 *   "AWB": {
 *     "geometry":{
 *       "type":"Polygon",
 *       "coordinates":[
 *         [[-69.89912109375,12.452001953124991],[-69.895703125,12.422998046874994],[-69.94218749999999,12.438525390624989],[-70.004150390625,12.50048828125],[-70.06611328125,12.546972656249991],[-70.05087890624999,12.597070312499994],[-70.035107421875,12.614111328124991],[-69.97314453125,12.567626953125],[-69.91181640625,12.48046875],[-69.89912109375,12.452001953124991]]
 *       ]
 *     },
 *     "properties": {
 *       "sovereignt":"Netherlands",
 *       "admin":"Aruba",
 *       "name":"Aruba",
 *       "iso_a3":"ABW",
 *       "continent":"North America",
 *       "region_un":"Americas",
 *       "subregion":"Caribbean",
 *       "region_wb":"Latin America & Caribbean",
 *       "featureclass":"Admin-0 country"
 *     },
 *     "type":"Feature"
 *   },
 *   "AFG": { ... }
 * }
 */
export async function loadGeoDataIndex() {
  let idx = await fetch(ApiEndpoints.geoData)
              .then(res => res.json())
              .then(data => _.keyBy(data.features, 'properties.iso_a3'));
  return idx;
}

/**
 * @typedef {Object} CountryRank
 * @property {number} id Stratus specific country id
 * @property {string} name
 * @property {number} rank Rank according to the subcategories provided
 * @property {string} isoA3 ISO 3166-1 alphs-3 code
 */

/**
 * Queries the API for a list of Countries and their rank according to the subcategories provided
 * 
 * @returns {Promise<{ countries: Object<string, CountryRank> }>} ranking data structured as an array of objects
 * @example
 * {
 *   "countries" : [
 *     {
 *       "id":141,
 *       "name":"Afghanistan",
 *       "rank":2,
 *       "isoA3":"AFG"
 *     },
 *     {
 *        "id":148,
 *       "name":"Pakistan",
 *       "rank":4,
 *       "isoA3":"PAK"
 *     },
 *     { ... }
 *   ]
 * }
 */
export function loadRankingData(subCategoriesDelim) {
  const query = API.JP_CCR_Data('countries', subCategoriesDelim);
  return apolloQuery( query );
}

/**
 * @typedef {Object} Indicator
 * @property {number} id
 * @property {string} indicatorName
 * @property {number} index
 * @property {number} rank
 * @property {string} indicatorFriendlyName
 * @property {string} indicatorDescription
 * @property {string} indicatorDescriptionPrayerPoint
 * @property {string} indicatorDescriptionThirdLevel
 * @property {number} indicatorWeight
 * @property {'Y'|'N'} flagInvertYN
 * @property {'Y'|'N'} flagPrayerPointYN
 * @property {number} value
 * @property {number} valueRaw
 * @property {number} valueTransform
 * @property {string} unitType
 * @property {string} valueType
 * @property {string} dataType
 * @property {string} sourceName
 * @property {string} sourceDescription
 * @property {object} [formula]
 * @property {number} formula.indicatorId
 * @property {string} formula.name
 * @property {'Y'|'N'} formula.flagInvertYN
 * @property {name} formula.friendlyName
 * @property {name} formula.transformInstructions
 * @property {string} formula.originalFormula
 * @property {string} formula.sqlCode
 * @property {number} formula.weight
 * @property {string} formula.conditions
 */

/**
 * @typedef {Object} Country
 * @property {number} id
 * @property {string} name
 * @property {string} isoA3 ISO 3166-1 alpha-3 country abbreviation
 * @property {string} isoA2 BCP47 2 letter country abbreviation
 * @property {string} jpCode The Joshua Project's 3 letter country abbreviation
 * @property {number} latitude
 * @property {number} longitude
 * @property {number} rank
 * @property {number} index
 * @property {number} population
 * @property {string} primaryLanguage
 * @property {string} primaryReligion
 * @property {number} percentChristianity
 * @property {number} percentEvangelical
 * @property {number} populationLeastReached
 * @property {number} percentLeastReached
 * @property {number} percentLeastReachedWorld
 * @property {number} evangelicalAnnualGrowthRate
 * @property {number} missionariesNeeded
 * @property {number} populationFrontier
 * @property {number} percentFrontier
 * @property {number} peopleGroupsPopulation
 * @property {number} peopleGroupsCount
 * @property {number} peopleGroupsUnreachedCount
 * @property {number} peopleGroupsFrontierCount
 * @property {number} peopleGroupsUnreachedPercent
 * @property {number} peopleGroupsFrontierPercent
 * @property {number} incompleteBibleCount
 * @property {number} incompleteBiblePercent
 * @property {number} noScriptureAccessCount
 * @property {number} noScriptureAccessPercent
 * @property {object[]} cities
 * @property {name} cities[].id
 * @property {string} cities[].name
 * @property {number} cities[].population
 * @property {'Y'|'N'} cities[].flagIsCapitalYN
 * @property {object[]} peopleGroups
 * @property {number} peopleGroups[].id
 * @property {string} peopleGroups[].name
 * @property {number} peopleGroups[].peopleId
 * @property {number} peopleGroups[].population
 * @property {number} peopleGroups[].percentPopulation
 * @property {string} peopleGroups[].primaryLanguage
 * @property {boolean} peopleGroups[].flagIndigenousCode
 * @property {number} peopleGroups[].bibleStatus
 * @property {number} peopleGroups[].latitude
 * @property {number} peopleGroups[].longitude
 * @property {number} peopleGroups[].percentAdherent
 * @property {number} peopleGroups[].percentEvangelical
 * @property {number} peopleGroups[].percentReachedness
 * @property {number} peopleGroups[].reachedness
 * @property {number} peopleGroups[].peopleId2
 * @property {object[]} [languages]
 * @property {number} languages[].id
 * @property {string} languages[].name
 * @property {'OL'|null} languages[].flagOfficialLanguage
 * @property {'DL'|null} languages[].flagDeFactoOfficialLanguage
 * @property {'NL'|null} languages[].flagNationalLanguage
 * @property {'LF'|null} languages[].flagLinguaFranca
 * @property {'LF'|null} languages[].source
 * @property {string} languages[].sort
 * @property {object[]} [languageGroups]
 * @property {number} languageGroups[].id
 * @property {string} languageGroups[].languageCode
 * @property {string} languageGroups[].name
 * @property {string} languageGroups[].sharedName
 * @property {number} languageGroups[].population
 * @property {number} languageGroups[].percentPopulation
 * @property {number} languageGroups[].numPeopleGroups
 * @property {boolean} languageGroups[].flagIndigenousCode
 * @property {number} languageGroups[].bibleStatus
 * @property {number} languageGroups[].percentAdherent
 * @property {number} languageGroups[].percentEvangelical
 * @property {number} languageGroups[].percentReachedness
 * @property {number} languageGroups[].reachedness
 * @property {object[]} [groups]
 * @property {number} groups[].id
 * @property {string} groups[].name
 * @property {number} groups[].index
 * @property {number} groups[].rank
 * @property {string} groups[].description
 * @property {object[]} groups[].categories
 * @property {number} groups[].categories[].id
 * @property {string} groups[].categories[].name
 * @property {number} groups[].categories[].index
 * @property {number} groups[].categories[].rank
 * @property {string} groups[].categories[].description
 * @property {object[]} groups[].categories[].subCategories
 * @property {number} groups[].categories[].subCategories[].id
 * @property {string} groups[].categories[].subCategories[].code
 * @property {string} groups[].categories[].subCategories[].name
 * @property {number} groups[].categories[].subCategories[].index
 * @property {number} groups[].categories[].subCategories[].rank
 * @property {string} groups[].categories[].subCategories[].description
 * @property {Indicator[]} groups[].categories[].subCategories[].indicators
 */

/**
 * 
 * @param {number} id Id of the country continent or region to query
 * @param {string} subCategoriesDelim Comma delimited string of subcategory codes to query against
 * @param {'country'|'continent'|'region'} view The type of object to return
 * @param {boolean} fetchFormulas Whether or not to populate the formulas fields.  Note: these are protected fields adn require a login
 * @returns {Promise<{ country: Country }>}
 */
export function fetchCountryData(id, subCategoriesDelim, view, fetchFormulas = false) {
  // safety check; let's not query for nothing
  if (id === null) return Promise.resolve();

  const query = API.queryCountry(id, subCategoriesDelim, view, fetchFormulas);
  return /** @type {Promise<{ country: Country }>} */ apolloQuery( query );
}

/**
 * @typedef {Object} CountryWithIndicator
 * 
 * @property {number} id
 * @property {string} name
 * @property {string} isoA3 ISO 3166-1 alpha-3 Country abbreviation
 * @property {object} indicator(id: ${(indicatorId) ? indicatorId : 5})
 * @property {number} indicator.index
 * @property {number} indicator.valueRank
 * @property {number} indicator.rank
 * @property {string} indicator.indicatorName
 * @property {string} indicator.indicatorFriendlyName e.g. Printable Name
 * @property {'Y'|'N'} indicator.flagInvertYN
 */

/**
 * Query the API and return countries along with the nested indicator and the country rank data
 * according to the indicator
 * 
 * @param {number} indicatorId 
 * @returns {Promise<{ countries: CountryWithIndicator[] }}
 */
export function fetchCountriesWithIndicators(indicatorId) {
  if (indicatorId === null) return Promise.resolve();

  const query = API.queryCountriesWithIndicators(indicatorId);
  return apolloQuery( query );
}

/**
 * @typedef {Object} CountryPrayerPoints
 * @property {number} id
 * @property {string} name
 * @property {object[]} indicators
 * @property {number} indicators[].id
 * @property {string} indicators[].indicatorName
 * @property {number} indicators[].index
 * @property {number} indicators[].rank
 * @property {string} indicators[].indicatorFriendlyName
 * @property {string} indicators[].indicatorDescription
 * @property {string} indicators[].indicatorDescriptionPrayerPoint
 * @property {string} indicators[].indicatorDescriptionThirdLevel
 * @property {number} indicators[].indicatorWeight
 * @property {'Y'|'N'} indicators[].flagInvertYN
 * @property {'Y'|'N'} indicators[].flagPrayerPointYN
 * @property {number} indicators[].value
 * @property {number} indicators[].valueRaw
 * @property {string} indicators[].unitType
 * @property {string} indicators[].valueType
 * @property {string} indicators[].dataType
 * @property {string} indicators[].sourceName
 * @property {string} indicators[].sourceDescription
 */

/**
 * Queries the API for indicator prayer points for each country.
 * 
 * This Query is cached.
 * 
 * @param {number} countryId
 * @returns {Promise<{ countries: CountryPrayerPoints[] }}
 */
export function fetchCountryPrayerPoints(countryId) {
  if (countryId === null) return Promise.resolve();

  const query = API.queryPrayerPoints(countryId);
  return apolloQuery( query );
}

/**
 * Query the api for people group aggregate statistics.
 * 
 * Result is cached in local storage.
 * 
 * @returns {Promise<{ aggregateStats: { peopleGroupMinReachedness: number, peopleGroupMaxReachedness: number } }>
 */
export function loadAggregateStats() {
  const query = API.statsQuery();
  return apolloQuery( query );
}

/**
 * @typedef {Object} GroupInfo
 * @property {number} id
 * @property {string} name
 * @property {object[]} categories
 * @property {number} categories[].id
 * @property {string} categories[].name
 * @property {object[]} categories[].subcategories
 * @property {number} categories[].subcategories[].id
 * @property {string} categories[].subcategories[].name
 * @property {string} categories[].subcategories[].code Short code that identifies the subcategory for filtering
 */

/**
 * Query the API for group, category and subcategory codes
 * 
 * Response is cached.
 * 
 * @param {*} id 
 * @returns {Promise<{ groups: GroupInfo[] }>}
 */
export function getGroupData(id) {
  const query = API.queryGroups(id);
  return apolloQuery( query );
}

const MILLISECONDS_IN_HOUR = 1000 * 60 * 60;
const MAX_CACHE_HOURS = 1;
const CURRENT_VERSION = 100090000;


//RAJ20210101 this will need revisitng if these get very big may need to switch to local storage or pruning 
function getCacheItem(cache,q){

  const maxCacheTime = Date.now() - (MAX_CACHE_HOURS * MILLISECONDS_IN_HOUR);
  let retVal = localStorage.getItem(cache+'_'+md5(q));
  if (retVal != null){
  
    retVal=JSON.parse(retVal);    
    if (retVal.w){
   
      sleep(1000); //give 100ms for wait state to clear
    }
    retVal = localStorage.getItem(cache+'_'+md5(q));
    retVal=JSON.parse(retVal);
    if (retVal.w){
      return false;
    }

    let ts = retVal.ts || maxCacheTime;
    let v = retVal.v || (CURRENT_VERSION - 1);
    if (ts <= maxCacheTime || v < CURRENT_VERSION) {
      deleteCacheItem(cache, retVal);
      return false;
    }

    return retVal.r;    
  }  
  return false;
}

function setCacheItem(cache,val){  
  cleanCache(cache,10);
  localStorage.setItem(cache+'_'+md5(val.q),JSON.stringify(val));  
}

function deleteCacheItem(cache,val){    
  localStorage.removeItem(cache+'_'+md5(val.q),JSON.stringify(val));
}

function cleanCache(cache,maxItems){
  var ar = [];
  var itm;
  for (var i = localStorage.length-1; i >= 0; i--){
      try {   
      itm = JSON.parse(localStorage.getItem(localStorage.key(i)));        
      if (localStorage.key(i).startsWith(cache+"_")) //list any keys to include
          ar.push([localStorage.key(i),itm.ts]);                           
      }
      catch (error) {}      
      }
  ar.sort(function(a,b) {
      return b[1]-a[1]
  });  
  for (var i=0;i<ar.length;i++){
      if (i > maxItems-1) //enter number of backups-1 here.
       localStorage.removeItem(ar[i][0]); //remove older backups
  }  
}

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

var skipCache = false; //disable cache here
/**
 * Queries the GraphQL api and caches the results in Local Storage.  When an identical query is tried, then
 * the cached value is returned
 *
 * @param {string} query GraphQL query
 * @returns {Promise}
 */
async function apolloQuery(query) {  
  query = query.replace('\n','').replace('\t','').replace(/\s+/g, ' ').trim();
  let r = false;
  if (!skipCache) r=getCacheItem('stratusCache',query); //20210104 RAJ TODO: md5 query everywhere once debugging is done so it's not stored in full length.
  if (r){
    return r;
  }
  else { 
  setCacheItem('stratusCache',{ ts:Date.now(),q:query,w:true, v:CURRENT_VERSION});
  let api_response = await fetchStratus({ query: query });
  if (api_response.error == null)
    setCacheItem('stratusCache',{ ts:Date.now(),q:query,r:api_response.data,w:false, v:CURRENT_VERSION});
  else
    deleteCacheItem('stratusCache',{ ts:Date.now(),q:query,r:api_response.data,w:false, v:CURRENT_VERSION});
  return api_response.data;
  }
}

export async function login(username, password) {
  // no need to cache this request
  let api_response = await fetchStratus({ query: API.queryLogin(username, password) });
  if (api_response.errors) {
    throw api_response.errors;
  }

  localStorage.setItem("token", api_response.data.login?.token);
  return api_response.data;
}

/**
 * @typedef {Object} Formula
 * @property {number} indicatorId
 * @property {string} name
 * @property {string} flagInvertYN
 * @property {string} friendlyName
 * @property {string} transformInstructions
 * @property {string} originalFormula The formula used to calculate indicator values in the original source spreadsheet.
 * @property {string} sqlCode The SQL (Postgres flavor) formula used to calculate the indicator value for the API.
 * @property {number} weight
 * @property {string[]} conditions
 */

/**
 * Queries the API for the list of formulas used for calculating the stratus index for each indicator
 *
 * @returns {Promise<Formula[]>}
 */
export function getFormulas() {
  return apolloQuery( API.formulas );
}
