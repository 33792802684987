import React, { useState, useEffect, useRef, useCallback, Suspense, useMemo } from 'react';
import { useRouteMatch, useParams, useHistory } from 'react-router';
import { ECTutorialProvider } from './ExpandCountry/ExpandCountryComponents/ECTutorialContext/ECTutorialContext';
import ErrorBox from 'components/ErrorBox/ErrorBox';
import { useGlobe } from './Context/GlobeContext';
import { useLang } from './Context/LangContext';
import { CountryExplorerProvider } from "./Context/CountryExplorerContext";
import { DataMainProvider } from "./Context/DataMainContext";
import GlobeBody from "./GlobeBody/GlobeBody";
import GlobeHeader from "./GlobeHeader/GlobeHeader";
/* Styling */
import "./../../assets/css/style.css";
import "./../../assets/css/stylesChanged.css";

/* Components */
import Globe from './Globe/Globe';
import { useTheme } from '../../lib/theme';
import { useRankingData } from '../../lib/rankingData';



import { useTranslation } from "react-i18next";
import Loading from 'components/Loading/Loading';
import GA4React, { useGA4React } from 'ga-4-react';
import AppConfig from "App.config";
import { Helmet } from 'react-helmet';
import CodeSplitting from './CodeSplitting';

const ga4react = new GA4React(AppConfig.GOOGLE.GA_TRACKING_CODE);



const Mask = React.lazy(() => import('./Mask/Mask'));
const ExpandCountryModal = React.lazy(() => import("components/GlobeComponents/ExpandCountry/ExpandCountryModal")); 
const NavFilter = React.lazy(() => import('./Navbar/NavFilter'));
const Switch = React.lazy(() => import('./Switch/Switch'));
const SearchFilter = React.lazy(() => import('./SearchFilter/SearchFilter'));
const HomeTutorial = React.lazy(() => import('./HomeTutorial/HomeTutorial'));


// TODO: De-duplicate
const tutVisitSuffix = '_tutorial_visited';

function TutorialState(){
    /* LOAD COUNTRY EXPLORER */
    const [isHomeTutorialVisible, setIsHomeTutorialVisible] = useState(localStorage.getItem('home' + tutVisitSuffix) !== '1');
    const openTutorial = useCallback(() => setIsHomeTutorialVisible(true), [setIsHomeTutorialVisible]);
    const closeTutorial = useCallback(() => {
      localStorage.setItem('home' + tutVisitSuffix, '1');
      setIsHomeTutorialVisible(false);
    }, [setIsHomeTutorialVisible]);
    
    const TutorialObject = {
      isHomeTutorialVisible: isHomeTutorialVisible,
      openTutorial: openTutorial,
      closeTutorial: closeTutorial
    };
    return TutorialObject;
};



export default function GlobeExplorer() {
  const { actions: { setStateIsDark }, stateIsDark  } = useTheme();
  const { globe, rotate } = useGlobe();
  const { i18n } = useTranslation("CountryExplorer");
  const { selectedISOA3, local } = useParams();
  const history = useHistory();
    
 

  useEffect(() => {
      ga4react.initialize().then((ga4) => {
        ga4.pageview('path');
        ga4.gtag('event','pageview','path') // or your custom gtag event
      },(err) => {
        console.error(err)
      });
  },[])
  
  
  useEffect(() => {
    let mounted = true;
    if(globe !== null && mounted){
        globe?.current?.pointOfView({ altitude: 1.45, lat: 25, lng: 70 }, 3000) //this sets initial view of globe 
        return () => {
           return mounted = false;
       }
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    if(globe.current !== undefined && mounted){
      globe.current.controls().autoRotate = rotate.rotate;
      globe.current.controls().autoRotateSpeed = rotate.speed;
      return () => {
       return mounted = false;
        };
    };
  }, [rotate.rotate]);

  let currentTarget = null;
  const { isHomeTutorialVisible, openTutorial, closeTutorial } = TutorialState();
  const { rankingData } = useRankingData();
  const [ whenRender, setWhenRender ] = useState(false);
  

  const currentDataScope = "countries";

  /*  SEARCH OVERLAY */
  const [overlay, setOverlay] = useState(false);
  

  const modalPathMatch = useRouteMatch({ path: '*/country-explorer/*', exact: true });
  
  const isModalOpen = Boolean(modalPathMatch);
  

  if (rankingData.data && rankingData.data[currentDataScope] && rankingData.data[currentDataScope][selectedISOA3]) {
      currentTarget = rankingData.data[currentDataScope][selectedISOA3];
  };


  if (!rankingData.loading && selectedISOA3 && !rankingData.data[currentDataScope][selectedISOA3]) {
    history.replace(`/${local}/globe-explorer/`);
  };


  /******************  */

  /* Handle search overlay */
  const HandleOverlay = (value) => {
    setOverlay(value);
  };
  
  const handleModeChange = (mode) => {
    if (mode === 'dark') {
      setStateIsDark(true);
    }
    else {
      setStateIsDark(false);
    }
  };

  React.useEffect(() => {
	  if(currentTarget !== null && globe.current && !whenRender){
		 	setTimeout(() => { setWhenRender(true) }, 12000); 
		}
	},[currentTarget]);

  return <ECTutorialProvider value={{ openTutorial }}>
        <Helmet>
            <title>{i18n.t(`Countries.${currentTarget?.name}`, currentTarget?.name )} {(currentTarget?.name) ? " |" : ""} { i18n.t(`PageText.Stratus index`, "Stratus Index")}</title>
        </Helmet>
        <div className={`relative p-4 md:px-0 md:py-8 2xl:py-10 bg-zircon h-screen min-h-224 overflow-y-auto flex flex-col justify-between 2xl:min-h-full 2xl:justify-start dark:bg-cod-gray pointer-events-auto `}>
              {/* <!-- GLOBE Placeholder --> */}
              <section className="absolute inset-0 overflow-hidden 2xl:transform 2xl:-translate-x-20 3xl:translate-x-0 pointer-events-auto">
                {/* <!--
                 Replace this placeholder image with your canvas globe.
                 Apply the following classes to it: w-full h-full object-cover
                 --> */}
                 <Globe
                    rankingData={rankingData}               
                    ccrData={rankingData} 
                    currentDataScope={currentDataScope}                 
                    stateIsDark={stateIsDark} 
                    isModalOpen={isModalOpen}
                    currentTarget={currentTarget}
                    />
              </section>
            {/* <!-- ENDs globe placeholder --> */}
              <GlobeHeader setOverlay={setOverlay} globe={globe} />
            {/* <!-- FILTER --> */}
            <CodeSplitting checker={globe.current} visible={true} classes="absolute top-0 left-0 w-full h-full opacity-50" size="lg" >
              <NavFilter />
            </CodeSplitting>
            {/* <!-- CONTENT --> */}
            {/* <!-- pointer-events --> */}
            {/* Jesse changed pointer-events from none to auto to allow user to open country explorer with YOURFOCUSMOBILE */}
                <GlobeBody
                  currentTarget={currentTarget}
                  globe={globe}
                  handleModeChange={handleModeChange} 
                  stateIsDark={stateIsDark}
                  rankingData={rankingData}
                  HandleOverlay={HandleOverlay}
                  isModalOpen={isModalOpen}
                  currentDataScope={currentDataScope} 
                  />
                {/* <!-- pointer-events --> */}
	              {/* <!-- Day / Night --> */}
                <CodeSplitting checker={globe.current} visible={true} classes="absolute top-0 left-0 w-full h-full opacity-50" size="lg" >
                  <Switch display="desktop" handleModeChange={handleModeChange} />
                </CodeSplitting>
            </div>
            {/* <!-- MASK --> */} {/* this works */}
            <CodeSplitting checker={globe.current} visible={true} classes="absolute top-0 left-0 w-full h-full opacity-50" size="lg" >
              <Mask isModalOpen={isModalOpen} isHomeTutorialVisible={isHomeTutorialVisible} />
            </CodeSplitting>
          {/* <!-- TUTORIAL: home page --> */}
          <CodeSplitting checker={globe.current} visible={true} classes="absolute top-0 left-0 w-full h-full opacity-50" size="lg" >
                <HomeTutorial name="home" isVisible={isHomeTutorialVisible} onClose={closeTutorial} />
    	  	      <SearchFilter ec={false} currentDataScope={currentDataScope}  HandleOverlay={HandleOverlay} display={ overlay ? "search-overlay" : "" } />
          </CodeSplitting>
			    {/* Error Message */}
			    <ErrorBox />
        	{/* Country Explorer Modal */}
          <CodeSplitting checker={globe.current} visible={false} classes="absolute top-0 left-0 w-full h-full opacity-50" size="lg" >
                {( isModalOpen || whenRender )
                ? <CountryExplorerProvider>
                  <DataMainProvider>
                    <ExpandCountryModal 
        	          currentDataScope={currentDataScope}
        	          skipAnimation={isModalOpen}
                    currentTarget={currentTarget}
                    i18n={i18n}
                    isModalOpen={isModalOpen}
        	          />
                    </DataMainProvider>
                </CountryExplorerProvider>
                : ""}
          </CodeSplitting>

        </ECTutorialProvider>
};

