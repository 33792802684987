import pick from 'lodash/pick';
import get from 'lodash/get';
import { ColorMap } from './constants';

/** @typedef {import('./api').CountryGeometry} CountryGeometry */

/**
 * Utility function to retrieve color spec from the defined authoritative "Stratus Colour Codes" set,
 * given a specific rank, day/night, muted or not, and desired response format.
 *
 * @param {object} opts Options for generating the colo
 * @param {number} [opts.rank] Country stratus rank.  Not used if rankIdx is passed instead
 * @param {number} [opts.rankIdx] Raw Color index
 * @param {boolean|'day'|'light'|'night'|'dark'} [opts.theme='day'] Toggle for dark / light mode. 'day', 'light'
 *                                                                   true represent Light mode.  'night', 'dark'
 *                                                                   and false represent Dark mode.
 * @param {boolean} [opts.muted=false] Whether or not a muted color should be returned.  Used when indicators are filtered
 * @param {'hex'|'rgb'} [opts.format='hex'] The format to return. 'hex' for a HTML color string and 'rgb' for an rgb
 *                                          triplet array
 * @param {string|number[]} [opts.defaultColor] What to return if no match is found.
 * @return {string|number[]}  Either hex string (e.g. '#5e0014') or rgb array (e.g. [94, 0, 20])
 *
 * @example
 * // Stratus rank 23 (defaults: day mode, unmuted, hex string):
 * colorFor({ rank: 23 })  // "#980005"
 * @example
 * // Stratus rank 137, night mode, muted color, rgb array:
 * colorFor({ rank: 137, theme: true, muted: true, format: 'rgb' })  // [208,195,130]
 */
export const colorFor = ({ rank, rankIdx, theme = 'day', muted = false, format = 'hex', defaultColor = null, divisor = 5 }) => {
  // TODO: theme should be consistent instead of 3 different values for each light and dark
  // handle defaults
  theme = [true,'dark','night'].includes(theme) ? 'night' : 'day';
  //filter  
  muted = muted === true ? 'muted' : 'full';

  // If we got a rank instead of a straight index, we need to do a little conversion math.
  // First, if the number of countries per color grouping ever changes, update this value:
  // const divisor = 5;
  // ...but if we got a rankIdx, just use it
  rankIdx = isFinite(rankIdx) ? rankIdx : Math.floor((rank - 1) / divisor);
  // If we happen to get a number higher than the highest available rank,
  // just use the top rank
  if (rankIdx >= colorMap.length) rankIdx = colorMap.length - 1

  window.latestColorSearch = {
    rank: rank,
    rankIdx: rankIdx,
    theme: theme,
    muted: muted,
    format: format
  }

  return get(ColorMap, [rankIdx, theme, muted, format], defaultColor);
}
window.colorMap = ColorMap;
window.colorFor = colorFor;

/**
 * @typedef {Object} CountryRanking
 * @property {number} id Stratus specific unique identifier
 * @property {string} name
 * @property {number} rank Ranking relative to other countries in the list
 * @property {number} isoA3 ISO 3 letter abbreviation for the country
 * @property {number} latitude
 * @property {number} longitude
 * @property {string|number} [color] The ranking color of the country
 * @property {CountryGeometry} [geometry] Latitude Longitude coordinate pairs that describe the country geometry
 */

// countries-only indexing
/**
 * Basic idea: create an object index in one shot that combines geographic and ranking data
 * for quick lookup wherever needed. The bonus utility of this approach was more evident when
 * it included the continent and region data, but it still works well even if countries are 
 * all we have. Keeping the approach consistent also enables us to go back to including the
 * other two scopes in the future if desired with minimal code change.
 *
 * Resulting data structure:
 *
 * {
 *   "countries": {
 *     "AFG": {
 *       "id":141,
 *       "name":"Afghanistan",
 *       "rank":2,
 *       "isoA3":"AFG",
 *       "color": {
 *         "false":[94,0,20,1],
 *         "true":"#5E0014"
 *       },
 *       "geometry":{
 *         "type":"Polygon",
 *         "coordinates": [[ ... ]],
 *       },
 *       "__id":"80213150"
 *     },
 *     "AGO": { ... },
 *   }
 * }
 * 
 * @returns {{ countries: Object<string, CountryRanking> }}
 */
export const buildRankingDataIndex = (rankingData, geoDataIndex, filtersApplied, maxCountries) => {
  const divisor = maxCountries / 40;
  const colorForRank = (rank) => {
    const format = 'rgb';
    //const defaultColor = [245, 245, 220];
    const defaultColor = [204, 204, 204]; //unset color for country countrycolor
    // if we go ahead and store both color variants, we don't have to re-index everything
    // just because the user picked the other theme
    const day = colorFor({ rank, format, muted: !filtersApplied, defaultColor, divisor });
    const night = colorFor({ rank, theme: 'night', format, muted: !filtersApplied, defaultColor, divisor });
    const ecDay = colorFor({ rank, format, defaultColor, divisor });
    const ecNight = colorFor({ rank, theme: 'night', format, defaultColor, divisor });
    return { false: day, true: night, ExpandCountry:{ false: ecDay, true: ecNight } };
  }

  /** @type {{ countries: Object<string, CountryRanking>}} */
  const polygonData = {
    countries: {}
  }
  //window.dLog(JSON.stringify(rankingData.countries),0);

  //ADD GREENLAND
  rankingData.countries.unshift({"id":undefined,"name":"Greenland","rank":undefined,"latitude":13.25,"longitude":-59.56667,"isoA3":"GRL"});
  //ADD WESTERN SAHARA
  rankingData.countries.unshift({"id":undefined,"name":"Western Sahara","rank":undefined,"latitude":24.2183123,"longitude":-12.8855964,"isoA3":"ESH"});
  //ADD FRENCH GUIANA
  rankingData.countries.unshift({"id":undefined,"name":"French Guiana","rank":undefined,"latitude":3.9332383,"longitude":-57.0875742,"isoA3":"FRG"});
  //ADD PALESTINE
  rankingData.countries.unshift({"id":undefined,"name":"Palestine","rank":undefined,"latitude":31.947351,"longitude":35.227163,"isoA3":"PSE"});
  //ADD Mayotte
  rankingData.countries.unshift({"id":undefined,"name":"Mayotte","rank":undefined,"latitude":-12.83916331,"longitude":45.136332788,"isoA3":"FMT"}); 
  //ADD Reunion
  rankingData.countries.unshift({"id":undefined,"name":"Reunion","rank":undefined,"latitude":-20.8789010,"longitude":55.448101,"isoA3":"REU"});  
  
  rankingData.countries && rankingData.countries.forEach(sourceCountry => {
    const country = pick(sourceCountry, ['id', 'name', 'rank', 'isoA3', 'latitude', 'longitude']);
    const geoCountry = geoDataIndex[country.isoA3];

    if (geoCountry) {
      // country.region_id = region.id;
      // country.continent_id = continent.id;
      country.color = colorForRank(country.rank);
      country.geometry = geoCountry.geometry;
      polygonData.countries[country.isoA3] = country;
    } else {
      window.dLog('missing geometry data for:' + JSON.stringify(country),8);
    }
  })

  return polygonData;
}

/**
 * 
 * @param {*} rankingData 
 * @param {string} currentDataScope Defaults to "countries"
 * @returns {{min: number, max: number}}
 */
export const getRankingRange = (rankingData, currentDataScope) => {
  let sortedItems = Object.values(rankingData?.[currentDataScope] || {})
    .filter(c => Number.isInteger(c.rank));
  
  if (sortedItems.length > 0) {
    let min = sortedItems[0].rank;
    let max = sortedItems[sortedItems.length - 1].rank;

    return { min, max };
  }

  return { min: 1, max: 200 };
};





/* checking for value in array */
export const containsTrue = (array) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i] === true) {
      return true;
    }
  }
  return false
};


