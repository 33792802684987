import React from 'react';
import { isEmpty } from 'lodash';
import { useNavGroup } from 'components/GlobeComponents/Context/NavGroupContext';

const ErrorBox = () => {
    const {errorMessage, actionNav} = useNavGroup();
    const msg = errorMessage;
    const bgImage = 'url(\'/img/error-popup-bg.svg\')';

    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        // idea is if we're passed a message then we open, and callback should clear the message.
        // not sure I like the approach though, might change it later
        if (!isEmpty(msg)) {
            setOpen(true);
        }
    }, [msg]);

    const handleClose = () => {
        setOpen(false);
        actionNav.setErrorMessage(null);
    }

    const headerImg = <>
        <div className="bg-red-100 dark:bg-red-200 h-12 relative rounded-full w-12">
            <svg
                className="-translate-x-1/2 -translate-y-3 absolute dark:text-black fill-current h-5 left-1/2 text-red-600 top-1/2 transform w-auto"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 16.07"
            >
                <path d="M16.2,16.07H1.8a1.8,1.8,0,0,1-1.56-2.7L7.44.9h0a1.8,1.8,0,0,1,3.12,0l7.2,12.47a1.8,1.8,0,0,1-1.56,2.7ZM8.4,1.45,1.2,13.92a.7.7,0,0,0,0,.7.69.69,0,0,0,.6.34H16.2a.69.69,0,0,0,.6-.34.7.7,0,0,0,0-.7L9.6,1.45a.69.69,0,0,0-1.2,0Z"></path>
                <circle cx="9" cy="13.03" r="0.5"></circle>
                <path d="M9,11.37a.5.5,0,0,1-.5-.5v-6a.5.5,0,0,1,1,0v6A.5.5,0,0,1,9,11.37Z"></path>
            </svg>
        </div>
    </>;

    /* Jesse added "fixed bg-white bg-opacity-90 dark:bg-black dark:bg-opacity-75" */
    
    return (
        <div className={`${open ? '' : 'hidden'} top-0 left-0 flex flex-col h-screen items-center justify-center py-8 w-screen z-modal fixed bg-white bg-opacity-90 dark:bg-black dark:bg-opacity-75 `}>
            <div className="bg-white dark:bg-cod-gray-900 max-w-sm p-6 rounded-lg shadow-md w-full">
                <div className="flex flex-col items-center">
                    {headerImg}
                    <div className="dark:text-white mt-3 text-2xl text-center text-gray-900 tracking-wide">Sorry!</div>
                    <p className="dark:text-cod-gray-600 font-normal mt-1 text-center text-gray-600 text-sm">{msg}</p>
                    <div className="mt-6 w-full">
                        <a className="bg-red-600 block cursor-pointer dark:bg-red-300 dark:hover:bg-red-400 dark:text-black duration-200 ease-in-out font-normal hover:bg-red-700 py-2 rounded-lg text-center text-sm text-white transition-colors" onClick={handleClose}>Go back to dashboard</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorBox;