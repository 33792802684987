import React, { useState, useContext, useEffect, useCallback } from 'react';

const ThemeContext = React.createContext();
const ThemeConsumer = ThemeContext.Consumer;

const cssDarkMode = window.matchMedia('(prefers-color-scheme: dark)');

const ThemeProvider = ({ children }) => {
    const [stateIsDark, setStateIsDark] = useState(cssDarkMode.matches || Boolean(Number.parseInt(localStorage.getItem('stateIsDark'), 10)));

    useEffect(() => {
        cssDarkMode.addEventListener('change', (event) => {
            setStateIsDark(event.matches);
        });
    }, [setStateIsDark]);

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0];
        if (stateIsDark) {
            body.classList.add('state-dark')
        } else {
            body.classList.remove('state-dark')
        }
        localStorage.setItem('stateIsDark', stateIsDark ? '1' : '0');
    },[stateIsDark]);

    return (
        <ThemeContext.Provider
            value={{
                stateIsDark: stateIsDark,
                actions: {
                    setStateIsDark
                }
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

const useTheme = () => useContext(ThemeContext);

export {
    ThemeContext,
    ThemeProvider,
    ThemeConsumer,
    useTheme
};
