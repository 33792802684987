import React, { useState, useContext } from "react";

const ZoomContext = React.createContext(null);
const ZoomConsumer = ZoomContext.Consumer;


function ZoomProvider({ children }){
    const [coords, setCoords] = useState({ longitude: null, latitude: null, altitude: null });

    
    return <ZoomContext.Provider value={{ 
        coords: coords,
        actions:{
            setCoords: setCoords,
        }
        }}>
        {children}
    </ZoomContext.Provider>
};

const useZoom = () => useContext(ZoomContext);

export {
  ZoomContext,
  ZoomConsumer,
  ZoomProvider,
  useZoom
};