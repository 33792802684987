import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'lib/theme';

const backgroundImages = {
    light_sm: 'url(\'/img/_Loader White  400px.gif\')',
    light_md: 'url(\'/img/_Loader White 600px.gif\')',
    light_lg: 'url(\'/img/_Loader White  1080px.gif\')',
    dark_white_sm: 'url(\'/img/_Loader DarkWhite 400px.gif\')',
    dark_white_md: 'url(\'/img/_Loader DarkWhite 600px.gif\')',
    dark_white_lg: 'url(\'/img/_Loader DarkWhite 1080px.gif\')',
    dark_sm: 'url(\'/img/_Loader Dark 400px.gif\')',
    dark_md: 'url(\'/img/_Loader Dark 600px.gif\')',
    dark_lg: 'url(\'/img/_Loader Dark 1080px.gif\')',
};

/**
 * @typedef {object} LoadingProps
 * @property {boolean} visible
 * @property {'sm'|'md'|'lg'} size
 * @property {string} className
 */

// TODO: Need to clean up the rest parameter.  This was done for style purposes, but bad idea in general

/**
 * @param {LoadingProps} props
 */
export default function Loading({ visible, size = 'sm', className = '', noWhite = false, ...props }) {
    const { stateIsDark } = useTheme();
    const backgroundImageKey =
        (stateIsDark ? 'dark' : 'light') +
        ((stateIsDark && !noWhite) ? '_white' : '') +
        '_' + size;
    const backgroundImage = backgroundImages[backgroundImageKey];

    const { style = {}, ...rest } = props;
    style.backgroundColor = stateIsDark ? '#00000b' : '#fff';
    style.backgroundImage = backgroundImage;

    return visible && (
        <div
            className={`bg-center bg-contain bg-no-repeat ${className}`}
            style={style}
            {...rest}
        ></div>
    );
}

Loading.FullScreen = () => <Loading visible size="lg" className="top-0 left-0 w-full h-full opacity-50 fixed z-modal" />

Loading.propTypes = {
    visible: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    className: PropTypes.string,
    noWhite: PropTypes.bool,
};

Loading.defaultProps = {
    visible: false,
    size: 'sm',
    className: '',
    noWhite: false
};
