import React, { useContext, useState, useCallback, useEffect } from 'react';
import { login } from '../components/GlobeComponents/api';

const AuthContext = React.createContext();

// TODO: add expiration data to payload
const parseToken = (token) => ({
    token,
    isAuthenticated: Boolean(token) //TODO: Validate token
});

const AuthProvider = ({ children }) => {
    const [state, setState] = useState({
        ...parseToken(localStorage.getItem('token')),
        isLoading: false,
        errors: []
    });

    useEffect(() => {
        if (state.token) {
            localStorage.setItem('token', state.token)
        } else {
            localStorage.removeItem('token');
        }
    }, [state.token]);

    const authenticate = useCallback((username, password) => {
        setState(prevState => ({ ...prevState, isLoading: true, errors: [] }));
        login(username, password)
            .then((res) => {
                if (!res?.login?.token){
                    setState({ ...parseToken(null), isLoading: false, errors: ['Unable to Login'] });
                } else {
                    setState({ ...parseToken(res.login.token), isLoading: false, errors: [] })
                }
            })
            .catch((err) => {
                console.error(err);
                let errors = [];
                if (Array.isArray(err)) {
                    errors = err.map(gqlErr => gqlErr.message);
                } else if (err.networkError) {
                    errors = [`Network Error: ${err.networkError}`];
                } else {
                    errors = ['System Error'];
                }
                setState({ ...parseToken(null), isLoading: false, errors });
            });
    }, [setState, login, parseToken]);

    const logout = useCallback(() => {
        setState(({ isLoading }) => ({ isLoading, token: null, isAuthenticated: false, errors: [] }));
    }, [setState]);

    return (
        <AuthContext.Provider value={{ ...state, authenticate, logout }}>
            { children }
        </AuthContext.Provider>
    );
}

const AuthConsumer = AuthContext.Consumer;

const useAuth = () => useContext(AuthContext);

export {
    AuthContext,
    AuthProvider,
    AuthConsumer,
    useAuth
}
