// export const Country_Api = "https://cors-anywhere.herokuapp.com/https://geojson-from-api.s3.us-east-2.amazonaws.com/geoJsonMinify.json"; 
// export const STRATUS_API = "http://api-dev.stratus.earth/api";


const continentQuery = `
                          regions{
                            id
                            name
                            rank
                            countries{
                              id
                              name
                              rank
                              isoA3
                            }
                          }`

const regionQuery = `countries{
                        isoA3
                      }`

const countryExplorerData = (fetchFormula) => `
cities {
    id,
    name,
    population,
    flagIsCapitalYN
},
peopleGroups {
    id,
    name,
    peopleId,
    population,
    percentPopulation,
    primaryLanguage,
    flagIndigenousCode,
    bibleStatus,
    latitude,
    longitude,
    percentAdherent,
    percentEvangelical,
    percentReachedness,
    reachedness,
    peopleId2
},
languages {
    id,
    name,
    flagOfficialLanguage,
    flagDeFactoOfficialLanguage,
    flagNationalLanguage,
    flagLinguaFranca,
    source,
    sort
},
languageGroups {
  id,
  languageCode,
  name,
  sharedName,
  population,
  percentPopulation,
  numPeopleGroups,
  flagIndigenousCode,
  bibleStatus,
  percentAdherent,
  percentEvangelical,
  percentReachedness,
  reachedness
},
groups {
  id,
  name,
  index,
  rank,
  description,
  categories {
      id,
      name,
      index,
      rank,
      description,
      subCategories {
          id,
          code,
          name,
          index,
          rank,
          description,
          indicators {
              id,            
              indicatorName,
              index,
              rank,
              percentile,
              indicatorFriendlyName,
              indicatorDescription,
              indicatorDescriptionPrayerPoint,
              indicatorDescriptionThirdLevel,
              indicatorWeight,
              flagInvertYN,
              flagPrayerPointYN,
              value,
              valueRaw,
              valueTransform,
              unitType,
              valueType,
              dataType,
              sourceName, ${fetchFormula ? `
              formula {
                indicatorId,
                name,
                flagInvertYN,
                friendlyName,
                transformInstructions,
                originalFormula,
                sqlCode,
                weight,
                conditions
              },` : '' }
              sourceDescription
          }
      }
  }      
}`

export const JP_CCR_Data = (ccr, subCategoriesDelim) => `{
                                ${ccr}(subCategoriesDelim:"${(subCategoriesDelim) ? subCategoriesDelim : ""}"){
                                id
                                name
                                rank
                                latitude
          	                    longitude
                                ${(ccr === "countries") ? "isoA3" : ""}
                                ${(ccr) === "regions" ? regionQuery : ""}
                                ${(ccr) === "continents" ? continentQuery : ""}
                                }
                            }`

export const Stratus_Group = (ccr, groupId) => `{
                                            ${ccr}{
                                                id
                                                name
                                                rank
                                                group(id:${groupId}){
                                                    name
                                                    rank
                                                }
                                                }
                                            }`

export const Stratus_Category = (ccr, groupId, categoryId) => ` {
                                                ${ccr}{
                                                  id
                                                  name
                                                  rank
                                                  group(id:${groupId}){
                                                    category(id:${categoryId}){
                                                      rank
                                                    }
                                                  }
                                                 }
                                                }`

export const queryFocusData = (ccr, ccrId, groupId) => ` {
                                                ${ccr}(id:${ccrId}){
                                                  name
                                                  rank
                                                  group(id:${groupId}){
                                                    name
                                                    rank
                                                    categories{
                                                      name
                                                      }
                                                    }
                                                  }
                                                }`

export const queryAutoData = (ccr) => ` {
                                    ${ccr}{
                                        id
                                        name
                                        }
                                    }`

export const statsQuery = () => `{
  aggregateStats {
    peopleGroupMinReachedness
    peopleGroupMaxReachedness
  }
}`;

export const queryCountry = (countryId, subCategoriesDelim, view, fetchFormula = false) => `{
                                      ${view} (id:${countryId}, subCategoriesDelim:"${(subCategoriesDelim) ? subCategoriesDelim : ""}") {
                                        id,
                                        name,
                                        isoA3,
                                        isoA2,
                                        jpCode,
                                        latitude,
          	                            longitude,
                                        rank,
                                        index,                                        
                                        population,
                                        primaryLanguage,
                                        primaryReligion,
                                        percentChristianity,
                                        percentEvangelical,
                                        populationLeastReached,
                                        percentLeastReached,
                                        percentLeastReachedWorld,
                                        evangelicalAnnualGrowthRate,
                                        missionariesNeeded,
                                        populationFrontier,
                                        percentFrontier,
                                        peopleGroupsPopulation,
                                        peopleGroupsCount,                                                                
                                        peopleGroupsUnreachedCount,
                                        peopleGroupsFrontierCount,
                                        peopleGroupsUnreachedPercent,
                                        peopleGroupsFrontierPercent,                                                                
                                        incompleteBibleCount,
                                        incompleteBiblePercent,
                                        noScriptureAccessCount,
                                        noScriptureAccessPercent,
                                        ${(countryId !== null ) ? countryExplorerData(fetchFormula) : ""}
                                          
                                      }
                                   }`;
export const queryGroups = (id) => id
  ? `{
      group(id: ${id}){
        id
        name
        categories{
          id
          name
          subCategories{
            id
            name
            code
          }
        }
      }
    }`
  : `{
      groups {
        id
        name
        categories{
          id
          name
          subCategories{
            id
            name
            code
          }
        }
      }
    }`;

export const queryCountriesWithIndicators = (indicatorId) => `{
      countries {
          id,
          name,
          isoA3
          indicator(id: ${(indicatorId) ? indicatorId : 5}){
              index
              valueRaw
              rank
              indicatorName
              indicatorFriendlyName
              flagInvertYN
            }
      }
  }`;


export const queryPrayerPoints = (countryId) => `{
  country(id: ${countryId}){
    id
    name
    groups {
      id
      name
      rank
      categories{
        id
        name
        rank
        subCategories{
          id
          name
          rank
          indicators {
            id
            indicatorName
            index
            rank
            indicatorFriendlyName
            indicatorDescription
            indicatorDescriptionPrayerPoint
            indicatorDescriptionThirdLevel
            indicatorWeight
            flagInvertYN
            flagPrayerPointYN
            value
            valueRaw
            unitType
            valueType
            dataType
            sourceName
            sourceDescription
            percentile
          }
        }
      }
    }
  }
}`;

export const queryLogin = (username, password) => `{
  login(username: "${username}", password: "${password}") {
    token
  }
}`
                 

export const formulas = `{
  formulas {
    indicatorId,
    name,
    friendlyName,
    transformInstructions,
    originalFormula,
    sqlCode,
    weight,
    conditions
  }
}`;