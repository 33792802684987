import React, { useContext } from "react";


const LangContext = React.createContext(null);
const LangConsumer = LangContext.Consumer;


function LangProvider({ children }){
    const [langState, setLangState] = React.useState({ isOpen: false, lang: "en"});


    return <LangContext.Provider value={{ 
        langState: langState,
       actionsLang:{
        setLangState: setLangState
        }}}
        >
        {children}
    </LangContext.Provider>
};

const useLang = () => useContext(LangContext);

export {
  LangContext,
  LangConsumer,
  LangProvider,
  useLang
};