import React, { useContext } from "react";

const CountryExplorerContext = React.createContext(null);
const CountryExplorerConsumer = CountryExplorerContext.Consumer;


function CountryExplorerProvider({ children }){
    const [countryExplorer, setCountryExplorer] = React.useState({ data: undefined, loading: true});

    return <CountryExplorerContext.Provider value={{ 
        countryExplorer: countryExplorer,
        actionCountryExplorer:{
        setCountryExplorer: setCountryExplorer
        }
        }}>
        {children}
    </CountryExplorerContext.Provider>
};

const useCountryExplorer = () => useContext(CountryExplorerContext);

export {
  CountryExplorerContext,
  CountryExplorerConsumer,
  CountryExplorerProvider,
  useCountryExplorer
};