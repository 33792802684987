import React, { useState, useEffect, useContext, useRef } from "react";
import { useTheme } from '../../../lib/theme';

const GlobeContext = React.createContext(null);
const GlobeConsumer = GlobeContext.Consumer;


function GlobeProvider({ children }){
 /* Rotating Globe */
 const globe = useRef();
 /* Globe Skin  */
 const [rotate, setRotate] = useState({ rotate: true, speed: .7 });
 /* const [renderGlobeExplorer, setRenderGlobeExplorer] = useState(false); */
 
/* Rotation */
  const handleRotation = (value) => {
    setRotate({
      rotate: value
    })
  }

  
  

    return <GlobeContext.Provider value={{ 
        globe: globe,
        rotate: rotate,
       actionsGlobe:{
        handleRotation: handleRotation,
        setRotate: setRotate
        }
        }}>
        {children}
    </GlobeContext.Provider>
};

const useGlobe = () => useContext(GlobeContext);

export {
  GlobeContext,
  GlobeConsumer,
  GlobeProvider,
  useGlobe
};