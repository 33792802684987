import React, { Suspense } from "react";
const DonateBtn = React.lazy(() => import("../NavbarOptions/DonateBtn"));
const LanguageSelect = React.lazy(() =>
  import("../NavbarOptions/Translation/LanguageSelect")
);
const SearchByCountry = React.lazy(() =>
  import("../NavbarOptions/SearchByCountry")
);
const Navbar = React.lazy(() => import("../Navbar/Navbar"));
import { useNavGroup } from "../Context/NavGroupContext";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading/Loading";
import CodeSplitting from "../CodeSplitting";
import { useLang } from "../Context/LangContext";

export default function GlobeHeader({ setOverlay, globe }) {
  const { i18n } = useTranslation();
  const { GroupFilters } = useNavGroup();
  const { langState } = useLang();

  const isFilterOpen = GroupFilters >= 0;
  const langClass = isFilterOpen || langState?.isOpen ? "z-50" : "";
  
  return (
    <header className={`js--filter-header relative `}>
      <div className="md:container">
        <div className="relative xl:flex xl:justify-between xl:items-center">
          {/* Navigation for xl <=  */}
          <CodeSplitting
            checker={globe.current}
            visible={true}
            classes="absolute top-0 left-0 w-full h-full opacity-50"
            size="lg"
          >
            <Navbar display="mobile" />
          </CodeSplitting>
          {/* <!-- Navigation for xl >= --> */}
          <div className="hidden xl:flex js--filter-nav flex items-center">
            {/* Navigation for xl > */}
            <CodeSplitting
              checker={globe.current}
              visible={true}
              classes="absolute top-0 left-0 w-full h-full opacity-50"
              size="lg"
            >
              {/* <!-- FILTER --> */}
              <span
                className="text-primary text-3xs mr-3 uppercase
               sm:text-2xs md:text-xs lg:mr-3 3xl:mr-6 dark:text-aqua z-40"
              >
                {i18n.t("PageText.Filter", "Filter")}:
              </span>
              <Navbar display="desktop" />
            </CodeSplitting>
          </div>
          {/* <!-- 3 icons --> */}
          <div className="absolute top-0 right-0 flex space-x-3 md:space-x-4 xl:relative 2xl:space-x-4">
            {/* <!-- search --> */}
            <CodeSplitting
              checker={globe.current}
              visible={true}
              classes="absolute top-0 left-0 w-full h-full opacity-50"
              size="lg"
            >
              <SearchByCountry setOverlay={setOverlay} />
            </CodeSplitting>
            {/* <!-- donate --> */}
            <CodeSplitting
              checker={globe.current}
              visible={true}
              classes="absolute top-0 left-0 w-full h-full opacity-50"
              size="lg"
            >
              <DonateBtn />
            </CodeSplitting>
            {/* <!-- language --> */}
            <CodeSplitting
              checker={globe.current}
              visible={true}
              classes="absolute top-0 left-0 w-full h-full opacity-50"
              size="lg"
            >
              <LanguageSelect langClass={langClass} />
            </CodeSplitting>
          </div>
        </div>
      </div>
    </header>
  );
}
