import React, { Suspense } from 'react'
import Loading from 'components/Loading/Loading';




export default function CodeSplitting({ children, checker, classes, size, visible }) {
    let intFrameHeight = window.innerHeight;
    return (
      <>
        {checker && (
          <Suspense
            fallback={
              <div style={{ height: `${intFrameHeight}px`, position: "fixed" }}>
                <Loading visible={visible} className={classes} size={size} />
              </div>
            }
          >
            {children}
          </Suspense>
        )}
      </>
    );
  }
