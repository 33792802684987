import React, { useContext } from "react";

const DataMainContext = React.createContext(null);
const DataMainConsumer = DataMainContext.Consumer;


function DataMainProvider({ children }){
    const [selectedGroupTab, setSelectedGroupTab] = React.useState("Spiritual");
    const [selectCategoryTab, setSelectCategoryTab] = React.useState("Christianity");
    const [indicatorSelected, setIndicatorSelected] = React.useState();
    const [indicatorData, setIndicatorData] = React.useState();

    const handleIndicator = (data) => {
      setIndicatorData(data)
    };

    return <DataMainContext.Provider value={{ 
        selectedGroupTab: selectedGroupTab,
        selectCategoryTab: selectCategoryTab,
        indicatorSelected: indicatorSelected,
        indicatorData: indicatorData,
        actionsDataMain:{
        setIndicatorData: setIndicatorData,
        setIndicatorSelected: setIndicatorSelected,
        setSelectCategoryTab: setSelectCategoryTab,
        setSelectedGroupTab: setSelectedGroupTab, 
        handleIndicator: handleIndicator,
        }
        }}>
        {children}
    </DataMainContext.Provider>
};

const useDataMain = () => useContext(DataMainContext);

export {
  DataMainContext,
  DataMainConsumer,
  DataMainProvider,
  useDataMain
};