import React, { useState, Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect, useHistory, useParams } from "react-router-dom";
import "./assets/css/style.css";
import "./assets/css/stylesChanged.css";

/* User Auth */
import { AuthProvider } from "./lib/authentication";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { ThemeProvider } from "./lib/theme";
import { RankingDataProvider } from "./lib/rankingData";
import { BreakPointProvider } from "./lib/breakpoints";
import { NavGroupProvider } from "./components/GlobeComponents/Context/NavGroupContext";
import { GlobeProvider } from "./components/GlobeComponents/Context/GlobeContext";
import { LangProvider } from "./components/GlobeComponents/Context/LangContext";
import { ZoomProvider } from "./components/GlobeComponents/Context/ZoomContext";
import { ReactComponent as LightBarGradient } from "./assets/img/LightBar.svg";
import { ReactComponent as LightBarMutedGradient } from "./assets/img/LightBarMuted.svg";
import { ReactComponent as DarkBarGradient } from "./assets/img/DarkBar.svg";
import { ReactComponent as DarkBarMutedGradient } from "./assets/img/DarkBarMuted.svg";
import Loading from "components/Loading/Loading";
import GlobeExplorer from "./components/GlobeComponents/GlobeExplorer";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { IndicatorCountryListProvider } from "components/GlobeComponents/Context/IndicatorCountryListContext";


const Login = React.lazy(() => import("./components/Auth/Login"));
const User = React.lazy(() => import("./components/Auth/User"));




const Providers = ({ children }) => (
  <ThemeProvider>
    <GlobeProvider>
      <ZoomProvider>
      <RankingDataProvider>
        <NavGroupProvider>
          <IndicatorCountryListProvider>
          <LangProvider>
            <AuthProvider>
                <BreakPointProvider
                  sizes={{
                    sm: 360,
                    md: 600,
                    lg: 768,
                    xl: 1024,
                    "2xl": 1280,
                    "3xl": 1440,
                  }}
                  >
                  {children}
              </BreakPointProvider>
          </AuthProvider>
        </LangProvider>
        </IndicatorCountryListProvider>
      </NavGroupProvider>
      </RankingDataProvider>
    </ZoomProvider>
  </GlobeProvider>
</ThemeProvider>
);


const App = () => {
  const i18n = useTranslation();
  const localLoaded = i18n?.store?.options?.lng;

    return (
    <Providers>
      {ReactDOM.createPortal(
        <div>
          <LightBarGradient />
          <LightBarMutedGradient />
          <DarkBarGradient />
          <DarkBarMutedGradient />
        </div>,
        document.getElementById("gradients")
      )}
      <BrowserRouter>
        <Helmet>
            <title>{i18n.t(`PageText.Stratus index`, "Stratus Index")}</title>
        </Helmet>
        <Switch>
          <Route
            exact
            strict
            path={[
              `/:local/country-explorer/:selectedISOA3`,
              `/:local/globe-explorer/`,
              `/:local/globe-explorer/:selectedISOA3`,
            ]}
          >
                <GlobeExplorer />
          </Route>

          <ProtectedRoute
            strict
            exact
            path={["/user/", "/user/:selectedISOA3"]}
            unAuthorizedPath="/login"
          >
           <Suspense fallback={<Loading.FullScreen />}>
              <User />
            </Suspense> 
          </ProtectedRoute>
          <Route exact path="/login">
            <Suspense fallback={<Loading.FullScreen />}>
              <Login />
            </Suspense>
          </Route>

          <Route exact path="/user">
            <Redirect exact strict to="/user/" />
          </Route>
          <Route>
            <Redirect exact strict to={`/${localLoaded ?  localLoaded : "en"}/globe-explorer/`} />
          </Route>
        </Switch>
      </BrowserRouter>
    </Providers>
  );
};

export default App;
